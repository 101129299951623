export const types = {
  GET_COUNTRIES_SUCCESS: 'GET_COUNTRIES_SUCCESS',
  GET_COUNTRIES: 'GET_COUNTRIES',
};

export const getCountrySuccess = (payload: any) => ({
  type: types.GET_COUNTRIES_SUCCESS,
  payload
});

export const getCountries = (payload: any) => ({
  type: types.GET_COUNTRIES,
  payload
});
