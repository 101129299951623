import { call, put, takeLatest } from 'redux-saga/effects';
import AuthServices from '../../services/auth';
import { changeLoading } from '../actions/app';
import {
  types,
  loginSuccess,
  getProfileSuccess,
  getProfileError,
  remindSuccess,
  getTokenSuccess,
} from '../actions/auth';
import { sagaPromise } from '../../lib/saga-promise';

export interface ResponseGenerator {
  config?: any;
  data?: any;
  headers?: any;
  request?: any;
  status?: number;
  statusText?: string;
}

interface Iaction {
  type: string;
  payload: any;
}

function* getProfile() {
  try {
    const data: ResponseGenerator = yield call(AuthServices.getProfile);
    yield put(getProfileSuccess(data));
    return data;
  } catch (error) {
    yield put(getProfileError());
    throw error;
  }
}

function* getToken(action: Iaction) {
  try {
    if (!action.payload.noLoading) {
      yield put(changeLoading(true));
    }
    const data: ResponseGenerator = yield call(AuthServices.getToken, action.payload);
    console.log('data');
    console.log(data);
    yield put(getTokenSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* remind(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(AuthServices.remind, action.payload);
    yield put(remindSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* login(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(AuthServices.login, action.payload);
    yield put(loginSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

export default function* authSaga() {
  yield takeLatest(types.LOGIN, sagaPromise(login));
  yield takeLatest(types.REMIND, sagaPromise(remind));
  yield takeLatest(types.GET_PROFILE, sagaPromise(getProfile));
  yield takeLatest(types.GET_TOKEN, sagaPromise(getToken));
}
