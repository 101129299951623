import { types } from '../actions/country';

interface CountryRequest {
  type: string;
  payload?: any;
}

type CountryActions = CountryRequest;

interface ICountryState {
  country: any;
  error: string | null;
  countries: any;
  allCountries: any;
  total: number;
}

const initialState: ICountryState = {
  country: [],
  error: null,
  countries: [],
  allCountries: [],
  total: 0,
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: CountryActions) => {
  switch (type) {
    case types.GET_COUNTRIES_SUCCESS:
      state.allCountries = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};
