import { all } from 'redux-saga/effects';

import authSaga from './auth';
import userSaga from './user';
import groupSaga from './group';
import licenseSaga from './license';
import countrySaga from './country';
import deviceSaga from './device';
import groupPasswordPolicySaga from './group_password_policy';

export default function* rootSaga() {
  yield all([
    authSaga(),
    userSaga(),
    groupSaga(),
    licenseSaga(),
    countrySaga(),
    deviceSaga(),
    groupPasswordPolicySaga(),
  ]);
}
