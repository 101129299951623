/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { start } from '../../lib/saga-promise';
import { groupDetail } from '../../state/actions/group';
import GroupCreate from './create';

export default function GroupEdit() {
  const dispatch = useDispatch();
  const param = useParams();
  const detail = useSelector((state: RootStateOrAny) => state.group.group);
  useEffect(() => {
    if (param.id) {
      start(groupDetail, { groupId: param.id }, dispatch);
    }
  }, []);
  return <>{detail.length !== 0 && detail.groupId === param.id && <GroupCreate {...detail} />}</>;
}
