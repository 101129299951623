import routes from '../constants/routes';

import Login from './auth/login';
import Remind from './auth/remind';
import PasswordReset from './auth/password_reset';
import Dashboard from './dashboard';

import MUserCreate from './muser/create';
import MUserEdit from './muser/edit';
import MUserSearch from './muser/search';

import UserCreate from './user/create';
import UserEdit from './user/edit';
import UserSearch from './user/search';
import UserImport from './user/import';
import ChangePassword from './user/changePassword';

import GroupSearch from './group/search';
import GroupCreate from './group/create';
import GroupEdit from './group/edit';
import PasswordPolicy from './group/password_policy/index';
import LicenseSearch from './license/search';
import LicenseCreate from './license/create';
import LicenseEdit from './license/edit';
import LicenseUser from './license/user/index';
import LicenseDevice from './license/device/index';
import LicenseImport from './license/import';

import Link from './link';
import NotFound from './notfound';
import UserLicenseImport from './license/user/import';
import PasswordExpiredChange from './auth/password_expired_change';

const routesPages = [
  {
    path: routes.dashboard,
    main: () => <Dashboard />,
  },
  {
    path: routes.login,
    noLayout: true,
    main: () => <Login />,
  },
  {
    path: routes.remind,
    noLayout: true,
    main: () => <Remind />,
  },
  {
    path: routes.passwordReset,
    noLayout: true,
    main: () => <PasswordReset />,
  },
  {
    path: routes.passwordExpiredChange,
    noLayout: true,
    main: () => <PasswordExpiredChange />,
  },
  {
    path: routes.muserCreate,
    main: () => <MUserCreate />,
  },
  {
    path: routes.muserEdit,
    main: () => <MUserEdit />,
  },
  {
    path: routes.muserSearch,
    main: () => <MUserSearch />,
  },
  {
    path: routes.userCreate,
    main: () => <UserCreate />,
  },
  {
    path: routes.userEdit,
    main: () => <UserEdit />,
  },
  {
    path: routes.userSearch,
    main: () => <UserSearch />,
  },
  {
    path: routes.userImport,
    main: () => <UserImport />,
  },
  {
    path: routes.groupSearch,
    main: () => <GroupSearch />,
  },
  {
    path: routes.groupCreate,
    main: () => <GroupCreate />,
  },
  {
    path: routes.groupEdit,
    main: () => <GroupEdit />,
  },
  {
    path: routes.licenseSearch,
    main: () => <LicenseSearch />,
  },
  {
    path: routes.licenseCreate,
    main: () => <LicenseCreate />,
  },
  {
    path: routes.licenseEdit,
    main: () => <LicenseEdit />,
  },
  {
    path: routes.licenseUser,
    main: () => <LicenseUser />,
  },
  {
    path: routes.licenseDevice,
    main: () => <LicenseDevice />,
  },
  {
    path: routes.licenseImport,
    main: () => <LicenseImport />,
  },
  {
    path: routes.userLicenseImport,
    main: () => <UserLicenseImport />,
  },
  {
    path: routes.passwordPolicy,
    main: () => <PasswordPolicy />,
  },
  {
    path: routes.link,
    noLayout: true,
    main: () => <Link />,
  },
  {
    path: routes.changePassword,
    main: () => <ChangePassword />,
  },
  {
    path: routes.notFound,
    noLayout: true,
    main: () => <NotFound />,
  },
];

export default routesPages;
