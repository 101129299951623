/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { ReactNode, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { UnpackNestedValue, useForm } from 'react-hook-form';
import { Typography } from '@mui/material';
import { USER_ROLE, SIZE_PAGE, DEFAULT_PAGE } from '../../constants/app';
import { searchMUser } from '../../state/actions/user';
import { getGroups } from '../../state/actions/group';
import { start } from '../../lib/saga-promise';
import roles from '../../constants/roles';
import routes from '../../constants/routes';
import { exportCsv } from '../../component/helpers/utility';
import SearchCriteria from '../../component/Layout/SearchCriteria';
import SearchForm from '../../component/Layout/SearchForm';
import SearchResult from '../../component/Layout/SearchResult';
import TGTextField from '../../component/Elements/TGTextField';
import RoleSelector from '../../component/Form/RoleSelector';
import GroupSelector from '../../component/Form/GroupSelector';
import IsInvalidCheckbox from '../../component/Form/IsInvalidCheckbox';
import RegistNewButton from '../../component/Form/RegistNewButton';
import DownloadButton from '../../component/Form/DownloadButton';
import { Column, columns, SearchCriteriaDefaultInput, SearchCriteriaInput } from './types';
import TGGrid from '../../component/Elements/TGGrid';
import OverHideText from '../../component/Elements/OverHideText';

const localStorageKey = 'msearchMUser';

export default function MUserSearch() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();

  const { register, handleSubmit, setValue, reset, getValues, watch } = useForm<SearchCriteriaInput>({
    defaultValues: SearchCriteriaDefaultInput,
  });

  const rows = useSelector((state: RootStateOrAny) => state.user);
  const groups = useSelector((state: RootStateOrAny) => state.group.allGroups);

  useEffect(() => {
    start(getGroups, { noLoading: true }, dispatch);
    setValueFromLocalStorage();
    handleSubmit(searchSubmit)();
  }, []);

  const handleSearch = () => {
    setValue('pageIndex', DEFAULT_PAGE);
    handleSubmit(searchSubmit)();
  };

  const searchSubmit = async (data: UnpackNestedValue<SearchCriteriaInput>) => {
    await start(searchMUser, data, dispatch);
    setItemLocalStorage({ ...data });
  };

  const setValueFromLocalStorage = () => {
    const searchLocalStorage = localStorage.getItem(localStorageKey);
    if (searchLocalStorage) {
      reset(JSON.parse(searchLocalStorage) as SearchCriteriaInput);
    }
  };

  const setItemLocalStorage = (params: SearchCriteriaInput) => {
    localStorage.setItem(localStorageKey, JSON.stringify(params));
  };

  const pageChange = (_: any, page: number) => {
    setValue('pageIndex', page);
    handleSubmit(searchSubmit)();
  };

  const handleExportCsv = (data: UnpackNestedValue<SearchCriteriaInput>) => {
    const url = `/user/mexport?loginId=${encodeURIComponent(data.loginId)}&username=${encodeURIComponent(data.username)}&groupId=${encodeURIComponent(data.groupId ?? '')}&roleId=${encodeURIComponent(data.roleId)}&isInvalid=${encodeURIComponent(data.isInvalid)}`;
    exportCsv(url, 'User');
  };

  return (
    <SearchForm title={t('muserSearch.label.top')} description={t('muserSearch.label.description')}>
      <TGGrid sx={{ mb: 2, mt: 2 }}>
        <SearchCriteria
          handleRefresh={() => reset(SearchCriteriaDefaultInput)}
          handleSearch={handleSearch}
        >
          <TGGrid item xs={12} sm={4}>
            <TGTextField registration={register('loginId')} label={t('common.label.userid')} />
          </TGGrid>
          <TGGrid item xs={12} sm={4}>
            <TGTextField registration={register('username')} label={t('common.label.username')} />
          </TGGrid>
          <TGGrid item xs={12} sm={4}>
            <RoleSelector
              roles={USER_ROLE.filter((role) => role.value !== roles.user)}
              handleChange={(event) => setValue('roleId', event.target.value)}
              roleId={watch('roleId')}
              hasBlank
            />
          </TGGrid>
          <TGGrid item xs={12} sm={12} md={4}>
            <GroupSelector
              groupId={watch('groupId')}
              handleChange={(data) => setValue('groupId', data?.groupId ?? null)}
              groups={groups}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={4}>
            <IsInvalidCheckbox registration={register('isInvalid')} checked={watch('isInvalid')} />
          </TGGrid>
        </SearchCriteria>
      </TGGrid>
      <TGGrid item xs={12} sx={{ float: 'right', mb: 2 }}>
        <RegistNewButton
          handleClick={() => {
            history(routes.muserCreate);
          }}
        />
        <DownloadButton handleClick={handleSubmit(handleExportCsv)} />
      </TGGrid>

      <TGGrid>
        <SearchResult<Column>
          totalCount={rows.total}
          page={getValues('pageIndex')}
          columns={columns}
          rows={rows?.musers}
          handlePageChange={pageChange}
          tableCell={(row: any, rowKey: number, column: Column): ReactNode => {
            const value = row[column.id];
            let color: any;
            switch (column.id) {
              case 'userId':
                return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
              case 'loginId':
                return (
                  <Link to={routes.muserEdit.replace(':id', row.userId)}>
                    <OverHideText>{value}</OverHideText>
                  </Link>
                );
              case 'roleId':
                switch (value) {
                  case roles.systemAdmin:
                    color = 'primary';
                    break;
                  case roles.groupAdmin:
                    color = 'success';
                    break;
                  case roles.user:
                    color = 'info';
                    break;
                  default:
                    return '';
                }
                return (
                  <>
                    <AccountBoxIcon color={color} sx={{ verticalAlign: 'bottom', mr: 0.5 }} />
                    {t(`common.label.role${value}`)}
                  </>
                );
              case 'isInvalid':
                return value ? <LockOutlinedIcon color="error" /> : '';
              default:
                return <OverHideText>{value}</OverHideText>;
            }
          }}
        />
      </TGGrid>
    </SearchForm>
  );
}
