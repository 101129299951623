import { Paper, Grid, Button } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { t } from 'i18next';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { changeEditable } from '../../state/actions/app';

type Props = {
  children: React.ReactNode;
  handleRefresh: () => void;
  handleSearch: () => void;
};

export default function SearchCriteria({ children, handleRefresh, handleSearch }: Props) {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(changeEditable(true));
  }, []);

  return (
    <Paper sx={{ backgroundColor: '#f7f5f5' }} elevation={1}>
      <Grid item xs={12} textAlign="right">
        <Button onClick={handleRefresh} style={{ color: 'blue' }}>
          {t('common.label.refresh')}
        </Button>
      </Grid>
      <Grid container spacing={1} direction="row" justifyContent="start" alignItems="center" sx={{ p: 2, pt: 0 }}>
        {children}
        <Grid item xs={12}>
          <Button onClick={handleSearch} variant="contained" sx={{ mx: 1 }} color="primary" startIcon={<SearchIcon />}>
            {t('common.btn.search')}
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
}
