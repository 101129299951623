import { Divider, List, Typography } from '@mui/material';
import { RootStateOrAny, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import NavItem from './navItem';
import NavCollapse from './navCollapse';

function NavGroup({ item }: any) {
  const { t } = useTranslation();
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const items = item.children?.map((menu: any) => {
    switch (menu.type) {
      case 'collapse':
        if (item.roles.includes(user.roleId)) {
          return <NavCollapse key={menu.id} menu={menu} level={1} />;
        }
        return null;
        break;
      case 'item':
        if (item.roles.includes(user.roleId)) {
          return <NavItem key={menu.id} item={menu} level={1} />;
        }
        return null;
        break;
      default:
        return (
          <Typography key={menu.id} variant="h6" color="error" align="center">
            Menu Items Error
          </Typography>
        );
    }
  });

  return (
    <>
      <List
        subheader={
          item.title && (
            <Typography
              variant="caption"
              display="block"
              gutterBottom
            >
              {t(item.title)}
              {item.caption && (
                <Typography
                  variant="caption"
                  display="block"
                  gutterBottom
                >
                  {item.caption}
                </Typography>
              )}
            </Typography>
          )
        }
      >
        {items}
      </List>
      <Divider sx={{ mt: 0.25, mb: 1.25 }} />
    </>
  );
}

export default NavGroup;
