// eslint-disable-next-line import/no-cycle
import { axiosClient } from './config';

class LicenseServices {
  static createLicense(params: any) {
    const url = 'license/create';
    return axiosClient.post(url, params);
  }

  static editLicense(params: any) {
    const url = 'license/edit';
    return axiosClient.put(url, params);
  }

  static searchLicenses(params: any) {
    const url = 'license/search';
    return axiosClient.get(url, { params });
  }

  static licenseDetail(params: any) {
    const url = 'license/detail';
    return axiosClient.get(url, { params });
  }

  static licenseImportUser(params: any) {
    const url = 'license/import/resgister_user';
    return axiosClient.post(url, params, {
      headers: {
        'content-type': 'multipart/form-data',
      },
    });
  }

  static licenseNewKey(params: any) {
    const url = 'license/NewKey';
    return axiosClient.get(url, { params });
  }
}
export default LicenseServices;
