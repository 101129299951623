export const BASE_URL = 'https://api.tgd-web.inc-next.com';
// export const BASE_URL = 'https://localhost:7266';
export const USER_ROLE = [
  {
    value: 1,
    label: 'common.label.role1',
  },
  {
    value: 2,
    label: 'common.label.role2',
  },
  {
    value: 3,
    label: 'common.label.role3',
  }
];
export const SIZE_PAGE = 20;
export const DEFAULT_PAGE = 1;
