/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { start } from '../lib/saga-promise';
import { getToken } from '../state/actions/auth';
import routes from '../constants/routes';

export default function Link() {
  const dispatch = useDispatch();
  const param = useParams();
  const token = useSelector((state: RootStateOrAny) => state.auth.token);
  const history = useNavigate();
  const initialRender = useRef(true);

  useEffect(() => {
    (async () => {
      try {
        if (param.id) {
          await start(getToken, { oneTimeKeyId: param.id }, dispatch);
          // history(token.url.replace(':id', token.oneTimeKeyId));
        }
      } catch (error) {
        history(routes.login);
      }
    })();
  }, []);

  useEffect(() => {
    if (!initialRender.current) {
      if (token.url) {
        history(token.url.replace(':id', token.oneTimeKeyId));
      } else {
        history(routes.login);
      }
    } else {
      initialRender.current = false;
    }
  }, [history, token]);

  return <></>;
}
