/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { ReactNode, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { UnpackNestedValue, useForm } from 'react-hook-form';
import { DEFAULT_PAGE, SIZE_PAGE } from '../../constants/app';
import { getGroups } from '../../state/actions/group';
import { start } from '../../lib/saga-promise';
import roles from '../../constants/roles';
import routes from '../../constants/routes';
import { exportCsv } from '../../component/helpers/utility';
import SearchCriteria from '../../component/Layout/SearchCriteria';
import SearchForm from '../../component/Layout/SearchForm';
import SearchResult from '../../component/Layout/SearchResult';
import GroupSelector from '../../component/Form/GroupSelector';
import IsInvalidCheckbox from '../../component/Form/IsInvalidCheckbox';
import RegistNewButton from '../../component/Form/RegistNewButton';
import DownloadButton from '../../component/Form/DownloadButton';
import { Column, columns, SearchCriteriaDefaultInput, SearchCriteriaInput } from './types';
import TGGrid from '../../component/Elements/TGGrid';
import IsIncludeExpiredRadio from '../../component/Form/IsIncludeExpiredRadio';
import { searchLicenses } from '../../state/actions/license';
import OverHideText from '../../component/Elements/OverHideText';
import ImportButton from '../../component/Form/ImportButton';

const localStorageKey = 'searchLicense';

export default function UserSearch() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();

  const { register, handleSubmit, setValue, reset, getValues, watch } = useForm<SearchCriteriaInput>({
    defaultValues: SearchCriteriaDefaultInput,
  });

  const rows = useSelector((state: RootStateOrAny) => state.license);
  const groups = useSelector((state: RootStateOrAny) => state.group.allGroups);
  const user = useSelector((state: RootStateOrAny) => state.auth.user);

  useEffect(() => {
    start(getGroups, { noLoading: true }, dispatch);
    setValueFromLocalStorage();
    handleSubmit(searchSubmit)();
  }, []);

  const handleSearch = () => {
    setValue('pageIndex', DEFAULT_PAGE);
    handleSubmit(searchSubmit)();
  };

  const searchSubmit = async (data: UnpackNestedValue<SearchCriteriaInput>) => {
    await start(searchLicenses, data, dispatch);
    setItemLocalStorage({ ...data });
  };

  const setValueFromLocalStorage = () => {
    const searchLocalStorage = localStorage.getItem(localStorageKey);
    if (searchLocalStorage) {
      reset(JSON.parse(searchLocalStorage) as SearchCriteriaInput);
    }
  };

  const setItemLocalStorage = (params: SearchCriteriaInput) => {
    localStorage.setItem(localStorageKey, JSON.stringify(params));
  };

  const pageChange = (_: any, page: number) => {
    setValue('pageIndex', page);
    handleSubmit(searchSubmit)();
  };

  const handleExportCsv = (data: UnpackNestedValue<SearchCriteriaInput>) => {
    const url = `/license/export?GroupId=${encodeURIComponent(data.groupId ?? '')}&isIncludeExpired=${encodeURIComponent(data.isIncludeExpired)}&isInvalid=${encodeURIComponent(data.isInvalid)}`;
    exportCsv(url, 'License');
  };

  return (
    <SearchForm
      title={t('licenseSearch.label.top')}
      description={t('licenseSearch.label.description')}
    >
      <TGGrid sx={{ mb: 2, mt: 2 }} userRoleId={user.roleId} showRoles={[roles.systemAdmin, roles.groupAdmin]}>
        <SearchCriteria
          handleRefresh={() => reset(SearchCriteriaDefaultInput)}
          handleSearch={handleSearch}
        >
          <TGGrid item xs={12} sm={12} md={4} userRoleId={user.roleId} showRoles={[roles.systemAdmin]}>
            <GroupSelector
              groupId={watch('groupId')}
              handleChange={(data) => setValue('groupId', data?.groupId ?? null)}
              groups={groups}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={4}>
            <IsIncludeExpiredRadio
              handleChange={(e, value) => setValue('isIncludeExpired', value === 'true')}
              value={watch('isIncludeExpired')}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={4}>
            <IsInvalidCheckbox registration={register('isInvalid')} checked={watch('isInvalid')} />
          </TGGrid>
        </SearchCriteria>
      </TGGrid>
      <TGGrid
        item
        xs={12}
        sx={{ float: 'right', mb: 2 }}
        userRoleId={user.roleId}
        showRoles={[roles.systemAdmin, roles.groupAdmin]}
      >
        <RegistNewButton
          handleClick={() => {
            history(routes.licenseCreate);
          }}
        />
        <ImportButton
          handleClick={() => {
            history(routes.licenseImport);
          }}
        />
        <DownloadButton handleClick={handleSubmit(handleExportCsv)} />
      </TGGrid>

      <TGGrid>
        <SearchResult<Column>
          totalCount={rows.total}
          page={getValues('pageIndex')}
          columns={columns}
          rows={rows?.licenses}
          handlePageChange={pageChange}
          tableCell={(row: any, rowKey: number, column: Column): ReactNode => {
            const value = row[column.id];
            switch (column.id) {
              case 'licenseId':
                return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
              case 'licenseCode':
                return (
                  <Link to={routes.licenseEdit.replace(':id', row.licenseId)}>
                    <OverHideText>{value}</OverHideText>
                  </Link>
                );
              case 'availableDeviceNumber':
                return <Link to={`${routes.licenseDevice}?licenseCode=${row.licenseCode}`}>{value}</Link>;
              case 'groupNameWithCode':
                return `${row.groupName} < ${row.groupCode} > `;
              case 'isInvalid':
                return value ? <LockOutlinedIcon color="error" /> : '';
              default:
                return column.format ? column.format(value) : <OverHideText>{value}</OverHideText>;
            }
          }}
        />
      </TGGrid>
    </SearchForm>
  );
}
