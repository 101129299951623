import {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconKey,
  IconHome,
  IconUserCircle,
  IconBuilding,
  IconLicense,
  IconListSearch,
  IconUserPlus,
  IconFolderPlus,
  IconUsers,
  IconListCheck,
  IconFilePlus,
  IconDeviceAnalytics
} from '@tabler/icons';
import { t } from 'i18next';

import roles from '../../../../constants/roles';
import routes from '../../../../constants/routes';

const icons = {
  IconTypography,
  IconPalette,
  IconShadow,
  IconWindmill,
  IconKey,
  IconHome,
  IconUserCircle,
  IconBuilding,
  IconLicense,
  IconListSearch,
  IconUserPlus,
  IconFolderPlus,
  IconUsers,
  IconListCheck,
  IconFilePlus,
  IconDeviceAnalytics
};

const menus = [
  {
    id: 'dashboard',
    title: '',
    type: 'group',
    roles: [roles.systemAdmin, roles.groupAdmin, roles.user],
    children: [
      {
        id: 'dashboard',
        title: 'dashboard.label.top',
        type: 'item',
        url: routes.dashboard,
        icon: icons.IconHome,
        roles: [roles.systemAdmin, roles.groupAdmin, roles.user],
        breadcrumbs: false,
      },
    ],
  },
  {
    id: 'utilities',
    title: '',
    type: 'group',
    roles: [roles.systemAdmin, roles.groupAdmin, roles.user],
    children: [
      {
        id: 'user',
        title: 'menu.btn.userManagement',
        type: 'collapse',
        icon: icons.IconUserCircle,
        roles: [roles.systemAdmin, roles.groupAdmin],
        children: [
          {
            id: 'searchMUser',
            title: 'menu.btn.adminUserSearch',
            type: 'item',
            url: routes.muserSearch,
            icon: icons.IconListSearch,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'createMUser',
            title: 'menu.btn.adminUserRegist',
            type: 'item',
            url: routes.muserCreate,
            icon: icons.IconUserPlus,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'searchUser',
            title: 'menu.btn.userMaster',
            type: 'item',
            url: routes.userSearch,
            icon: icons.IconUsers,
            roles: [roles.systemAdmin, roles.groupAdmin, roles.user],
            breadcrumbs: false,
          },
          {
            id: 'createUser',
            title: 'menu.btn.userRegist',
            type: 'item',
            url: routes.userCreate,
            icon: icons.IconUserPlus,
            roles: [roles.systemAdmin, roles.groupAdmin],
            breadcrumbs: false,
          },
        ],
      },
      {
        id: 'group',
        title: 'menu.btn.groupManagement',
        type: 'collapse',
        icon: icons.IconBuilding,
        roles: [roles.systemAdmin, roles.groupAdmin],
        children: [
          {
            id: 'searchGroup',
            title: 'menu.btn.groupSearch',
            type: 'item',
            url: routes.groupSearch,
            icon: icons.IconListSearch,
            roles: [roles.systemAdmin, roles.groupAdmin],
            breadcrumbs: false,
          },
          {
            id: 'createGroup',
            title: 'menu.btn.groupRegist',
            type: 'item',
            url: routes.groupCreate,
            icon: icons.IconFolderPlus,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
        ],
      },
      {
        id: 'license',
        title: 'menu.btn.licenseManagement',
        type: 'collapse',
        icon: icons.IconLicense,
        roles: [roles.systemAdmin, roles.groupAdmin, roles.user],
        children: [
          {
            id: 'searchLicense',
            title: 'menu.btn.licenseMaster',
            type: 'item',
            url: routes.licenseSearch,
            icon: icons.IconListSearch,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'createLicense',
            title: 'menu.btn.licenseRegist',
            type: 'item',
            url: routes.licenseCreate,
            icon: icons.IconFilePlus,
            roles: [roles.systemAdmin],
            breadcrumbs: false,
          },
          {
            id: 'userLicense',
            title: 'menu.btn.userLicenseSearch',
            type: 'item',
            url: routes.licenseUser,
            icon: icons.IconListCheck,
            roles: [roles.systemAdmin, roles.groupAdmin],
            breadcrumbs: false,
          },
          {
            id: 'deviceLicense',
            title: 'menu.btn.deviceSetting',
            type: 'item',
            url: routes.licenseDevice,
            icon: icons.IconDeviceAnalytics,
            roles: [roles.systemAdmin, roles.groupAdmin, roles.user],
            breadcrumbs: false,
          },
        ],
      },
    ],
  },
];

export default menus;
