/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { start } from '../../lib/saga-promise';
import { userDetail } from '../../state/actions/user';
import UserCreate from './create';

export default function UserEdit() {
  const dispatch = useDispatch();
  const param = useParams();
  const detail = useSelector((state: RootStateOrAny) => state.user.user);
  useEffect(() => {
    if (param.id) {
      start(userDetail, { userId: param.id }, dispatch);
    }
  }, []);

  return (
    <>
      {detail.length !== 0 && detail.userId === param.id && <UserCreate {...detail} />}
    </>
  );
}
