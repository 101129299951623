/* eslint-disable react/jsx-no-useless-fragment */
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { groupPasswordPolicyDetail } from '../../../state/actions/group_password_policy';
import { start } from '../../../lib/saga-promise';
import PasswordPolicyCreate from './edit';
import { groupDetail } from '../../../state/actions/group';

export default function GroupEdit() {
  const dispatch = useDispatch();
  const param = useParams();
  // eslint-disable-next-line max-len
  const policyDetail = useSelector((state: RootStateOrAny) => state.group_password_policy.group_password_policy);

  useEffect(() => {
    if (param.id) {
      start(groupPasswordPolicyDetail, { groupId: param.id }, dispatch);
    }
  }, []);
  return (
    // eslint-disable-next-line max-len
    <>{policyDetail.length !== 0 && policyDetail.groupId === param.id && <PasswordPolicyCreate {...policyDetail} />}</>
  );
}
