import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import appReducer from './app';
import authReducer from './auth';
import userReducer from './user';
import groupReducer from './group';
import licenseReducer from './license';
import countryReducer from './country';
import deviceReducer from './device';
import groupPasswordPolicyReducer from './group_password_policy';


const configs = {
  key: 'license',
  blacklist: ['app'],
  storage
};

const rootReducer = combineReducers({
  app: appReducer,
  auth: authReducer,
  user: userReducer,
  group: groupReducer,
  license: licenseReducer,
  country: countryReducer,
  device: deviceReducer,
  group_password_policy: groupPasswordPolicyReducer
});

export type AppState = ReturnType<typeof rootReducer>;

export default persistReducer(configs, rootReducer);
