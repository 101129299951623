import { types } from '../actions/license';

interface LicenseRequest {
  type: string;
  payload?: any;
}

type LicenseActions = LicenseRequest;

interface ILicenseState {
  license: any;
  error: string | null;
  licenses: any;
  total: number;
  newKey: string;
}

const initialState: ILicenseState = {
  license: [],
  error: null,
  licenses: [],
  total: 0,
  newKey: '',
};

// eslint-disable-next-line @typescript-eslint/default-param-last
export default (state = initialState, { type, payload }: LicenseActions) => {
  switch (type) {
    case types.CREATE_LICENSE_SUCCESS:
      state.license = payload;
      return {
        ...state,
      };
    case types.SEARCH_LICENSES_SUCCESS:
      state.licenses = payload.list;
      state.total = payload.count;
      return {
        ...state,
      };
    case types.LICENSE_DETAIL_SUCCESS:
      state.license = payload;
      return {
        ...state,
      };
    case types.EDIT_LICENSE_SUCCESS: {
      const index = state.licenses.findIndex((item: any) => item.licenseId === payload.licenseId);
      if (index >= 0) {
        state.licenses[index] = payload;
        state.license = payload;
      }
      return {
        ...state,
        pending: true,
      };
    }
    case types.LICENSE_IMPORT_USER_SUCCESS:
      return {
        ...state,
      };
    case types.LICENSE_NEW_KEY_SUCCESS:
      state.newKey = payload;
      return {
        ...state,
      };
    default:
      return state;
  }
};
