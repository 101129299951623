/* eslint-disable import/no-cycle */
import axios from 'axios';
import queryString from 'query-string';
import { BASE_URL } from '../constants/app';
import { logout } from '../state/actions/auth';
import store from '../state/store';

export const axiosClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    // Accept: 'application/json',
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Credentials': true,
  },
  paramsSerializer: (params) => queryString.stringify(params, {
    encode: true,
    arrayFormat: 'bracket'
  }),
});


axiosClient.interceptors.request.use(async (config: any) => {
  const token = localStorage.getItem('token');
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});


axiosClient.interceptors.response.use((response) => {
  if (response && response.data) {
    return response.data;
  }
  return response;
}, (error) => {
  // Do something with response error
  if (error.response?.status === 401) {
    store.dispatch(logout());
  }
  return Promise.reject(error.response);
});
