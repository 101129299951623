/* eslint-disable react/require-default-props */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable max-len */
import { Paper, TextField, Autocomplete } from '@mui/material';
import { t } from 'i18next';
import { useSelector, RootStateOrAny } from 'react-redux';
import IGroupDdl from '../../modal/IGroupDdl';

type Props = {
  groups: IGroupDdl[];
  handleChange: (data: IGroupDdl | null) => void;
  groupId: string | null;
  error?: boolean;
  message?: string | undefined;
  isDisable?: boolean;
};

export default function GroupSelector({ groups, handleChange, groupId, error, message, isDisable }: Props) {
  const changeGroup = (data: IGroupDdl | null) => {
    handleChange(data);
  };
  const setValue = (_groupId: string | null) => {
    const group = groups.find((item: IGroupDdl) => item.groupId === _groupId);
    return group ?? null;
  };
  const isEditable = useSelector((state: RootStateOrAny) => state.app.isEditable);

  return (
    <Autocomplete
      disablePortal
      size="small"
      readOnly={!isEditable || isDisable}
      popupIcon={!isEditable || isDisable ? '' : undefined}
      options={groups}
      // disabled={isDisable}
      getOptionLabel={(option: any) => `${option.groupName} <${option.groupCode}>`}
      noOptionsText={t('error.group.notFound')}
      value={setValue(groupId)}
      onChange={(_event, newValue: IGroupDdl | null) => {
        changeGroup(newValue);
      }}
      isOptionEqualToValue={() => true}
      // eslint-disable-next-line react/no-unstable-nested-components
      PaperComponent={(props: any) => <Paper elevation={16} {...props} />}
      renderInput={(params) => (
        <TextField
          {...params}
          label={t('common.label.groupnameGroupid')}
          error={error}
          helperText={t(message as string)}
          variant={!isEditable || isDisable ? 'standard' : 'outlined'}
        />
      )}
    />
  );
}
