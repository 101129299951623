import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

function PageNotFound() {
  const { t } = useTranslation();

  return (
    <Stack
      justifyContent="center"
      alignItems="center"
      sx={{ width: '100%', height: '90vh' }}
    >
      <Typography variant="h1">
        404
      </Typography>
      <Typography>
        {t('notFound')}
      </Typography>
    </Stack>
  );
}

export default PageNotFound;
