const routes = {
  login: '/',
  remind: '/remind',
  dashboard: '/dashboard',
  link: '/link/:id',
  passwordReset: '/password/reset/:id',
  passwordExpiredChange: '/password/expired',

  muserCreate: '/muser/create',
  muserEdit: '/muser/edit/:id',
  muserSearch: '/muser/search',

  userCreate: '/user/create',
  userEdit: '/user/edit/:id',
  userSearch: '/user/search',
  userImport: '/user/import',
  changePassword: '/user/change_password',

  groupSearch: '/group/search',
  groupCreate: '/group/create',
  groupEdit: '/group/edit/:id',
  passwordPolicy: '/group/password_policy/:id',

  licenseSearch: '/license/search',
  licenseCreate: '/license/create',
  licenseEdit: '/license/edit/:id',
  licenseUser: '/license/user',
  licenseDevice: '/license/device',
  licenseImport: '/license/import',
  userLicenseImport: '/license/user/import',

  notFound: '*'
};
export default routes;
