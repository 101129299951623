export const types = {
  CREATE_LICENSE: 'CREATE_LICENSE',
  CREATE_LICENSE_SUCCESS: 'CREATE_LICENSE_SUCCESS',
  EDIT_LICENSE: 'EDIT_LICENSE',
  EDIT_LICENSE_SUCCESS: 'EDIT_LICENSE_SUCCESS',
  SEARCH_LICENSES: 'SEARCH_LICENSES',
  SEARCH_LICENSES_SUCCESS: 'SEARCH_LICENSES_SUCCESS',
  LICENSE_DETAIL: 'LICENSE_DETAIL',
  LICENSE_DETAIL_SUCCESS: 'LICENSE_DETAIL_SUCCESS',
  LICENSE_IMPORT_USER: 'LICENSE_IMPORT_USER',
  LICENSE_IMPORT_USER_SUCCESS: 'LICENSE_IMPORT_USER',
  LICENSE_NEW_KEY: 'LICENSE_NEW_KEY',
  LICENSE_NEW_KEY_SUCCESS: 'LICENSE_NEW_KEY_SUCCESS'
};

export const createLicense = (payload: any) => ({
  type: types.CREATE_LICENSE,
  payload
});

export const createLicenseSuccess = (payload: any) => ({
  type: types.CREATE_LICENSE_SUCCESS,
  payload
});

export const editLicense = (payload: any) => ({
  type: types.EDIT_LICENSE,
  payload
});

export const editLicenseSuccess = (payload: any) => ({
  type: types.EDIT_LICENSE_SUCCESS,
  payload
});

export const searchLicenses = (payload: any) => ({
  type: types.SEARCH_LICENSES,
  payload
});

export const searchLicensesSuccess = (payload: any) => ({
  type: types.SEARCH_LICENSES_SUCCESS,
  payload
});

export const licenseDetail = (payload: any) => ({
  type: types.LICENSE_DETAIL,
  payload
});

export const licenseDetailSuccess = (payload: any) => ({
  type: types.LICENSE_DETAIL_SUCCESS,
  payload
});

export const licenseImportUser = (payload: any) => ({
  type: types.LICENSE_IMPORT_USER,
  payload,
});

export const licenseImportUserSuccess = (payload: any) => ({
  type: types.LICENSE_IMPORT_USER_SUCCESS,
  payload,
});

export const licenseNewKey = (payload: any) => ({
  type: types.LICENSE_NEW_KEY,
  payload,
});

export const licenseNewKeySuccess = (payload: any) => ({
  type: types.LICENSE_NEW_KEY_SUCCESS,
  payload,
});

