/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { ReactNode, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { UnpackNestedValue, useForm } from 'react-hook-form';
import { DEFAULT_PAGE, SIZE_PAGE } from '../../constants/app';
import { searchGroups } from '../../state/actions/group';
import { start } from '../../lib/saga-promise';
import roles from '../../constants/roles';
import routes from '../../constants/routes';
import { exportCsv } from '../../component/helpers/utility';
import SearchCriteria from '../../component/Layout/SearchCriteria';
import SearchForm from '../../component/Layout/SearchForm';
import SearchResult from '../../component/Layout/SearchResult';
import TGTextField from '../../component/Elements/TGTextField';
import IsInvalidCheckbox from '../../component/Form/IsInvalidCheckbox';
import RegistNewButton from '../../component/Form/RegistNewButton';
import DownloadButton from '../../component/Form/DownloadButton';
import { Column, columns, SearchCriteriaDefaultInput, SearchCriteriaInput } from './types';
import TGGrid from '../../component/Elements/TGGrid';
import CountrySelector from '../../component/Form/CountrySelector';
import { getCountries } from '../../state/actions/country';
import ICountryDdl from '../../modal/ICountryDdl';
import hasRole from '../../lib/hasRole';
import OverHideText from '../../component/Elements/OverHideText';

const localStorageKey = 'searchGroup';

export default function GroupSearch() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();

  const { register, handleSubmit, setValue, reset, getValues, watch } = useForm<SearchCriteriaInput>({
    defaultValues: SearchCriteriaDefaultInput,
  });

  const rows = useSelector((state: RootStateOrAny) => state.group);
  const countries = useSelector((state: RootStateOrAny) => state.country.allCountries);
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const hasAdmin = hasRole('systemAdmin', user);

  useEffect(() => {
    start(getCountries, { noLoading: true }, dispatch);
    setValueFromLocalStorage();
    handleSubmit(searchSubmit)();
  }, []);

  const handleSearch = () => {
    setValue('pageIndex', DEFAULT_PAGE);
    handleSubmit(searchSubmit)();
  };

  const searchSubmit = async (data: UnpackNestedValue<SearchCriteriaInput>) => {
    await start(searchGroups, data, dispatch);
    setItemLocalStorage({ ...data });
  };

  const setValueFromLocalStorage = () => {
    const searchLocalStorage = localStorage.getItem(localStorageKey);
    if (searchLocalStorage) {
      reset(JSON.parse(searchLocalStorage) as SearchCriteriaInput);
    }
  };

  const setItemLocalStorage = (params: SearchCriteriaInput) => {
    localStorage.setItem(localStorageKey, JSON.stringify(params));
  };

  const pageChange = (_: any, page: number) => {
    setValue('pageIndex', page);
    handleSubmit(searchSubmit)();
  };

  const handleExportCsv = (data: UnpackNestedValue<SearchCriteriaInput>) => {
    const url = `/group/export?groupCode=${encodeURIComponent(data.groupCode)}&groupName=${encodeURIComponent(data.groupName)}&countryId=${encodeURIComponent(data.countryId)}&isInvalid=${encodeURIComponent(data.isInvalid)}`;
    exportCsv(url, 'Group');
  };

  return (
    <SearchForm title={t('groupSearch.label.top')} description={t('groupSearch.label.description')}>
      <TGGrid sx={{ mb: 2, mt: 2 }} userRoleId={user.roleId} showRoles={[roles.systemAdmin]}>
        <SearchCriteria
          handleRefresh={() => reset(SearchCriteriaDefaultInput)}
          handleSearch={handleSearch}
        >
          <TGGrid item xs={12} sm={4}>
            <TGTextField registration={register('groupCode')} label={t('common.label.groupid')} />
          </TGGrid>
          <TGGrid item xs={12} sm={4}>
            <TGTextField registration={register('groupName')} label={t('common.label.groupname')} />
          </TGGrid>
          <TGGrid item xs={12} sm={4}>
            <CountrySelector
              countries={countries}
              countryId={watch('countryId')}
              handleChange={(data) => setValue('countryId', data?.countryId ?? '')}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={4}>
            <IsInvalidCheckbox registration={register('isInvalid')} checked={watch('isInvalid')} />
          </TGGrid>
        </SearchCriteria>
      </TGGrid>
      <TGGrid
        item
        xs={12}
        sx={{ float: 'right', mb: 2 }}
        userRoleId={user.roleId}
        showRoles={[roles.systemAdmin, roles.groupAdmin]}
      >
        {hasAdmin && (
          <RegistNewButton
            handleClick={() => {
              history(routes.groupCreate);
            }}
          />
        )}
        <DownloadButton handleClick={handleSubmit(handleExportCsv)} />
      </TGGrid>

      <TGGrid>
        <SearchResult<Column>
          totalCount={rows.total}
          page={getValues('pageIndex')}
          columns={columns}
          rows={rows?.groups}
          handlePageChange={pageChange}
          tableCell={(row: any, rowKey: number, column: Column): ReactNode => {
            const value = row[column.id];
            switch (column.id) {
              case 'groupId':
                return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
              case 'groupCode':
                return (
                  <Link to={routes.groupEdit.replace(':id', row.groupId)}>
                    <OverHideText>{value}</OverHideText>
                  </Link>
                );
              case 'passwordPolicyLink':
                return (
                  <Link to={routes.passwordPolicy.replace(':id', row.groupId)}>
                    {t('groupSearch.link.passwordPolicyLink')}
                  </Link>
                );
              case 'isInvalid':
                return value ? <LockOutlinedIcon color="error" /> : '';
              case 'countryId': {
                const country: ICountryDdl = countries.find((item: ICountryDdl) => item.countryId === value);

                if (!country) {
                  return '';
                }
                return (
                  <>
                    <img
                      loading="lazy"
                      width="20"
                      src={`https://flagcdn.com/w20/${country.alpha2.toLowerCase()}.png`}
                      srcSet={`https://flagcdn.com/w40/${country.alpha2.toLowerCase()}.png 2x`}
                      alt=""
                    />
                    {`${country.countryNameJp} <${country.countryId.toUpperCase()}>`}
                  </>
                );
              }
              default:
                return <OverHideText>{value}</OverHideText>;
            }
          }}
        />
      </TGGrid>
    </SearchForm>
  );
}
