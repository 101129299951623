import * as yup from 'yup';
import { t } from 'i18next';
import { DEFAULT_PAGE } from '../../constants/app';
import { TableColumn } from '../../types';

export type RegisterInput = {
  loginId: string;
  email: string;
  username: string;
  password: string;
  nickname: string;
  groupId: string | null;
  roleId: number;
  countryId: string;
  isInvalid: boolean;
};

export const schemaEdit = yup.object({
  // loginId: yup
  //   .string()
  //   .required(t('muserCreate.msg.requiredUserid'))
  //   .matches(/^[a-z0-9_]+$/i, t('common.msg.format.userid'))
  //   .max(100, t('muserCreate.msg.requiredUserid')),
  email: yup
    .string().required(('muserCreate.msg.requiredEmail')).email(('Email107'))
    .max(256, ('muserCreate.msg.requiredEmail')),
  username: yup
    .string().required(('muserCreate.msg.requiredUsername'))
    .max(256, ('muserCreate.msg.requiredUsername')),
  roleId: yup.string().required(('muserCreate.msg.requiredRole')),
  groupId: yup.string().typeError(('muserCreate.msg.requiredGroup')).required(('muserCreate.msg.requiredGroup')),
  countryId: yup.string().required(('groupCreate.msg.requiredCountryId')),
  nickname: yup
    .string()
    .required(('muserCreate.msg.nickname'))
    .max(256, ('muserCreate.msg.nickname')),
});

// export const schemaNew = schemaEdit.concat(
//   yup.object({
//     password: yup
//       .string()
//       .required(('muserCreate.msg.requiredPassword'))
//       .min(6, ('Password107'))
//       .max(127, ('muserCreate.msg.requiredPassword'))
//       .matches(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&].*$/, ('Password107')),
//   })
// );

export type Column = TableColumn & {
  id: 'userId' | 'loginId' | 'username' | 'groupNameWithCode' | 'countryId' | 'email' | 'nickname' | 'isInvalid';
}

export const columns: Column[] = [
  {
    id: 'userId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'groupNameWithCode',
    label: 'common.label.groupnameGroupid',
  },
  {
    id: 'loginId',
    label: 'common.label.userid',
  },
  {
    id: 'username',
    label: 'common.label.username',
  },
  {
    id: 'email',
    label: 'common.label.email',
  },
  {
    id: 'countryId',
    label: 'common.label.countryTitle',
  },
  {
    id: 'nickname',
    label: 'common.label.nickname',
  },
  {
    id: 'isInvalid',
    label: 'common.label.invalid',
    align: 'center',
  },
];

export type SearchCriteriaInput = {
  loginId: string;
  groupId: string | null;
  roleId: string;
  countryId: string;
  username: string;
  isInvalid: boolean;
  pageIndex: number;
};

export const SearchCriteriaDefaultInput: SearchCriteriaInput = {
  loginId: '',
  groupId: null,
  roleId: '0',
  countryId: '',
  username: '',
  isInvalid: false,
  pageIndex: DEFAULT_PAGE,
};
