import { useTheme } from '@mui/material/styles';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import {
  Box, Button, Divider, FormControl, Grid, MenuItem, Alert,
  Stack, TextField, Typography, useMediaQuery, Card, CardContent, ListItemText,
} from '@mui/material';
import { useEffect, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import Logo from '../layout/main/header/logo';
import { login as actionLogin, logout } from '../../state/actions/auth';
import { start } from '../../lib/saga-promise';
import routes from '../../constants/routes';

interface FormInput {
  loginId: string
  locate: string
  password: string,
}
interface ILocationState {
  prevLocation?: string
}

export default function Login() {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();

  const location = useLocation();
  const locationState = location.state as ILocationState;

  const loggedIn = useSelector((state: RootStateOrAny) => state.auth.loggedIn);

  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  const locate = localStorage.getItem('locate') ? localStorage.getItem('locate') : 'ja';
  const [valueLocate, setValueLocate] = useState<string | null>(locate);
  const [codeError, setCodeError] = useState<string>('');

  const schema = yup.object({
    loginId: yup.string().required(t('login.msg.userId.require')),
    locate: yup.string().required(t('common.msg.validate.require')),
    password: yup
      .string()
      .required(t('login.msg.password.require'))
  });

  const { register, handleSubmit, formState: { errors } } = useForm<FormInput>({
    resolver: yupResolver(schema),
  });

  const locates = [
    {
      name: t('login.lable.japanese'),
      value: 'ja'
    },
    {
      name: t('login.lable.english'),
      value: 'en',
    },
    {
      name: t('login.lable.chinese'),
      value: 'cn'
    }
  ];

  useEffect(() => {
    if (loggedIn) {
      if (locationState?.prevLocation && locationState?.prevLocation !== routes.login) {
        history(locationState.prevLocation);
      } else {
        history(routes.dashboard);
      }
    }
  }, [loggedIn, history, locationState]);

  const onSubmit: SubmitHandler<FormInput> = async ({ loginId, password }) => {
    try {
      await start(actionLogin, { loginId: loginId.trim(), password }, dispatch);
    } catch (error: any) {
      console.log('error');
      console.log(error);
      if (error?.data) {
        if (error?.data === 1060) {
          history(routes.remind);
        }
        setCodeError(t(error.data));
      } else {
        setCodeError(t('login.msg.error'));
      }
    }
  };

  const changeLocate = (event: ChangeEvent<HTMLInputElement>) => {
    setValueLocate(event.target.value);
    localStorage.setItem('locale', event.target.value);
    i18n.changeLanguage(event.target.value);
  };

  return (
    <div className="login__container">
      <Card className="login__card">
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'center' }} mt={5}>
            <Logo />
          </Box>
          <Stack alignItems="center" justifyContent="center" spacing={1}>
            <Typography
              color={theme.palette.primary.main}
              gutterBottom
              variant={matchDownSM ? 'h3' : 'h2'}
            >
              {t('login.lable.login')}
            </Typography>
          </Stack>
          {codeError
            && <Alert severity="error" icon={false}>{codeError}</Alert>}
          <Grid item xs={12}>
            <Grid container direction="column" justifyContent="center" mt={2}>
              <Stack>
                <ListItemText
                  primary={(
                    <Typography variant="h5" color="inherit">
                      {t('login.lable.locate')}
                    </Typography>
                  )}
                />
                <FormControl fullWidth sx={{ mb: 1 }}>
                  <TextField
                    select
                    fullWidth
                    {...register('locate')}
                    error={'locate' in errors}
                    helperText={errors.locate?.message}
                    onChange={changeLocate}
                    value={valueLocate}
                  >
                    {
                      locates.map((item, index) => (
                        // eslint-disable-next-line react/no-array-index-key
                        <MenuItem key={index} value={item.value}>{item.name}</MenuItem>
                      ))
                    }
                  </TextField>
                </FormControl>
                <ListItemText
                  primary={(
                    <Typography variant="h5" color="inherit">
                      {t('login.lable.id')}
                    </Typography>
                  )}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  autoComplete="loginId"
                  autoFocus
                  sx={{ mt: 0 }}
                  {...register('loginId')}
                  error={'loginId' in errors}
                  helperText={errors.loginId?.message}
                />
                <ListItemText
                  primary={(
                    <Typography variant="h5" color="inherit">
                      {t('login.lable.password')}
                    </Typography>
                  )}
                />
                <TextField
                  margin="normal"
                  required
                  fullWidth
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  sx={{ mt: 0 }}
                  {...register('password')}
                  error={'password' in errors}
                  helperText={errors.password?.message}
                />
                <Box sx={{ mt: 2 }}>
                  <Button
                    disableElevation
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit(onSubmit)}
                  >
                    {t('login.lable.login')}
                  </Button>
                </Box>
              </Stack>
            </Grid>
          </Grid>
          <Divider />
          <Grid item xs={12}>
            <Typography variant="body1" gutterBottom mt={2} sx={{ textAlign: 'center' }}>
              {t('login.lable.forgetPassword')}
              、
              <Link to={routes.remind}>{t('login.lable.forgetPassword.link')}</Link>
            </Typography>
          </Grid>
        </CardContent>
      </Card>
    </div>
  );
}
