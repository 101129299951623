// eslint-disable-next-line import/no-cycle
import { axiosClient } from './config';

class CountryServices {
  static getCountries(params: any) {
    const url = 'country/select';
    return axiosClient.get(url, { params });
  }
}
export default CountryServices;
