/* eslint-disable max-len */
import React, { useState, useRef, useEffect } from 'react';
import {
  IconSettings, IconLogout, IconUserCircle, IconArrowsRightLeft, IconLock
} from '@tabler/icons';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import {
  Avatar,
  useTheme,
  Box,
  Chip,
  ClickAwayListener,
  Divider,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Paper,
  Popper,
  Stack,
  Typography
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import Transitions from '../../../../component/extended/transitions';

import User1 from '../../../../assets/images/user-round.svg';
import customTheme from '../../../../themes/custom';
import MainCard from '../../../../component/maincard';
import { logout } from '../../../../state/actions/auth';
import routes from '../../../../constants/routes';


function ProfileSection() {
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const { t } = useTranslation();
  const theme = useTheme();
  const custom = customTheme();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const history = useNavigate();

  const [open, setOpen] = useState<boolean>(false);
  const prevOpen = useRef<boolean>(open);
  const anchorRef = useRef<HTMLDivElement>(null);
  const [selectedIndex, setSelectedIndex] = useState<number>(-1);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      if (anchorRef && anchorRef.current) {
        anchorRef.current.focus();
      }
    }
    prevOpen.current = open;
  }, [open]);

  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      if (anchorRef && anchorRef.current) {
        anchorRef.current.focus();
      }
    }

    prevOpen.current = open;
  }, [open]);

  const handleToggle = () => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event: any) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const handleListItemClick = (event: any, index: number, route = '') => {
    setSelectedIndex(index);
    handleClose(event);

    if (route && route !== '') {
      navigate(route);
    }
  };

  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <Chip
        sx={{
          height: '48px',
          alignItems: 'center',
          borderRadius: '27px',
          transition: 'all .2s ease-in-out',
          borderColor: theme.palette.primary.light,
          backgroundColor: theme.palette.primary.light,
          '&[aria-controls="menu-list-grow"], &:hover': {
            borderColor: theme.palette.primary.main,
            background: `${theme.palette.primary.main}!important`,
            color: theme.palette.primary.light,
            '& svg': {
              stroke: theme.palette.primary.light,
            },
          },
          '& .MuiChip-label': {
            lineHeight: 0,
          },
        }}
        // icon={(
        //   <Avatar
        //     src={User1}
        //     sx={{
        //       ...custom.mediumAvatar,
        //       margin: '8px 0 8px 8px !important',
        //       cursor: 'pointer',
        //     }}
        //     ref={anchorRef}
        //     aria-controls={open ? 'menu-list-grow' : undefined}
        //     aria-haspopup="true"
        //     color="inherit"
        //   />
        // )}
        label={<IconSettings stroke={1.5} size="1.5rem" color={theme.palette.primary.main} />}
        variant="outlined"
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
        color="primary"
      />
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: 'offset',
              options: {
                offset: [0, 14]
              }
            }
          ]
        }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" in={open} {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  border={false}
                  elevation={16}
                  content={false}
                  boxShadow
                  shadow={theme.shadows[16]}
                  sx={{ minHeight: 'auto' }}
                >
                  <Box sx={{ p: 2, pb: 0 }}>
                    <Stack>
                      <Typography variant="h4">
                        {`${user.username} < ${user.loginId} >`}
                      </Typography>
                      <Typography variant="h6">
                        {t(`common.label.role${user.roleId}`)}
                      </Typography>
                    </Stack>
                  </Box>
                  {/* <PerfectScrollbar style={{ maxHeight: '250px' }}> */}
                  <Box sx={{ p: 2 }}>
                    <Divider />
                    <List
                      component="nav"
                      sx={{
                        width: '100%',
                        maxWidth: 350,
                        minWidth: 300,
                        backgroundColor: theme.palette.background.paper,
                        borderRadius: '10px',
                        [theme.breakpoints.down('md')]: {
                          minWidth: '100%',
                        },
                        '& .MuiListItemButton-root': {
                          mt: 0.5
                        }
                      }}
                    >
                      {/* <ListItemButton
                        sx={{ borderRadius: '1px' }}
                        selected={selectedIndex === 0}
                        onClick={(event) => handleListItemClick(event, 0, '')}
                      >
                        <ListItemIcon>
                          <IconUserCircle stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">{t('プロファイル')}</Typography>} />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ borderRadius: '1px' }}
                        selected={selectedIndex === 1}
                        onClick={(event) => handleListItemClick(event, 1, '')}
                      >
                        <ListItemIcon>
                          <IconSettings stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">{t('設定')}</Typography>} />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ borderRadius: '1px' }}
                        selected={selectedIndex === 2}
                        onClick={(event) => handleListItemClick(event, 2, '')}
                      >
                        <ListItemIcon>
                          <IconArrowsRightLeft stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText
                          primary={(
                            <Grid container spacing={1} justifyContent="space-between">
                              <Grid item>
                                <Typography variant="body2">{t('パスワード変更')}</Typography>
                              </Grid>
                            </Grid>
                          )}
                        />
                      </ListItemButton>
                      <Divider /> */}
                      <ListItemButton
                        sx={{ borderRadius: '1px' }}
                        selected={selectedIndex === 4}
                        onClick={() => {
                          history(routes.changePassword);
                        }}
                      >
                        <ListItemIcon>
                          <IconLock stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">{t('header.btn.passwordChange')}</Typography>} />
                      </ListItemButton>
                      <ListItemButton
                        sx={{ borderRadius: '1px' }}
                        selected={selectedIndex === 4}
                        onClick={handleLogout}
                      >
                        <ListItemIcon>
                          <IconLogout stroke={1.5} size="1.3rem" />
                        </ListItemIcon>
                        <ListItemText primary={<Typography variant="body2">{t('header.btn.logout')}</Typography>} />
                      </ListItemButton>
                    </List>
                  </Box>
                  {/* </PerfectScrollbar> */}
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </>

  );
}

export default ProfileSection;
