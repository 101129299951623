/* eslint-disable react/require-default-props */
/* eslint-disable max-len */
import { TextField, TextFieldProps } from '@mui/material';
import { UseFormRegisterReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { RootStateOrAny, useSelector } from 'react-redux';

type TGTextFieldProps = TextFieldProps & {
  label: React.ReactNode;
  name?: string;
  type?: 'text' | 'email' | 'password' | 'date';
  isDisabled?: boolean;
  value?: unknown;
  onChange?: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement> | undefined;
  registration?: Partial<UseFormRegisterReturn>;
  isError?: boolean;
  errorMessage?: React.ReactNode;
};

export default function TGTextField({
  label,
  name,
  type,
  isDisabled,
  value,
  onChange,
  registration,
  isError,
  errorMessage,
  ...props
}: TGTextFieldProps) {
  const isEditable = useSelector((state: RootStateOrAny) => state.app.isEditable);
  const { t } = useTranslation();
  // const disableUnderline = !isEditable || isDisabled ? { disableUnderline: true } : {};
  return (
    <TextField
      fullWidth
      variant={!isEditable || isDisabled ? 'standard' : 'outlined'}
      size="small"
      label={label}
      name={name}
      type={type}
      InputProps={{
        readOnly: !isEditable || isDisabled,
        // ...disableUnderline,
      }}
      InputLabelProps={{
        shrink: type === 'date' ? true : undefined,
      }}
      value={value}
      onChange={onChange}
      {...registration}
      error={isError}
      helperText={t(errorMessage as string)}
      {...props}
    />
  );
}
