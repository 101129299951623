import * as yup from 'yup';
import { t } from 'i18next';
import roles from '../../constants/roles';
import { DEFAULT_PAGE } from '../../constants/app';
import { TableColumn } from '../../types';

export type RegisterInput = {
  loginId: string;
  email: string;
  username: string;
  password: string;
  nickname: string;
  groupId: string | null;
  roleId: string;
  countryId: string;
  isInvalid: boolean;
};

export const schemaEdit = yup.object({
  // loginId: yup
  //   .string()
  //   .required(t('muserCreate.msg.requiredUserid'))
  //   .max(100, t('muserCreate.msg.requiredUserid'))
  //   .matches(/^[a-z0-9_]+$/i, t('common.msg.format.userid')),
  email: yup
    .string().required('muserCreate.msg.requiredEmail').email('Email107')
    .max(256, 'muserCreate.msg.requiredEmail'),
  username: yup
    .string().required(('muserCreate.msg.requiredUsername'))
    .max(256, ('muserCreate.msg.requiredUsername')),
  roleId: yup.string().required(('muserCreate.msg.requiredRole')),
  groupId: yup.string().when('roleId', {
    is: roles.groupAdmin.toString(),
    then: yup
      .string().typeError(('muserCreate.msg.requiredGroup')).required(('muserCreate.msg.requiredGroup'))
      .max(100, ('muserCreate.msg.requiredGroup')),
    otherwise: yup.string().strip(), // false の場合はこの field を削除
  }),
  countryId: yup
    .string()
    .typeError(('groupCreate.msg.requiredCountryId'))
    .required(('groupCreate.msg.requiredCountryId')),
  nickname: yup
    .string()
    .typeError(('muserCreate.msg.nickname'))
    .required(('muserCreate.msg.nickname'))
    .max(256, ('muserCreate.msg.nickname')),
});

export type Column = TableColumn & {
  id: 'userId' | 'loginId' | 'username' | 'email' | 'roleId' | 'groupCode' | 'groupName' | 'isInvalid';
};

export const columns: Column[] = [
  {
    id: 'userId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'loginId',
    label: 'common.label.userid',
  },
  {
    id: 'username',
    label: 'common.label.username',
  },
  {
    id: 'email',
    label: 'common.label.email',
  },
  {
    id: 'roleId',
    label: 'common.label.role',
  },
  {
    id: 'groupCode',
    label: 'common.label.groupid',
  },
  {
    id: 'groupName',
    label: 'common.label.groupname',
  },
  {
    id: 'isInvalid',
    label: 'common.label.invalid',
    align: 'center',
  },
];

export type SearchCriteriaInput = {
  loginId: string;
  groupId: string | null;
  roleId: string;
  username: string;
  isInvalid: boolean;
  pageIndex: number;
};

export const SearchCriteriaDefaultInput: SearchCriteriaInput = {
  loginId: '',
  groupId: null,
  roleId: '0',
  username: '',
  isInvalid: false,
  pageIndex: DEFAULT_PAGE,
};
