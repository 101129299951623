/* eslint-disable no-nested-ternary */
/* eslint-disable max-len */
import { useNavigate } from 'react-router-dom';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UnpackNestedValue } from 'react-hook-form';
import { Divider } from '@mui/material';
import * as yup from 'yup';
import { useEffect, useState } from 'react';
import { passwordChange } from '../../state/actions/user';
import { start } from '../../lib/saga-promise';
import routes from '../../constants/routes';
import RegisterForm from '../../component/Layout/RegisterForm';
import TGTextField from '../../component/Elements/TGTextField';
import TGGrid from '../../component/Elements/TGGrid';
import { groupPasswordPolicyDetail } from '../../state/actions/group_password_policy';
import { getRegExpSchemaPasswordPolicy } from '../../component/helpers/utility';

type RegisterInput = {
  oldPassword: string;
  newPassword: string;
  newPasswordConfirm: string;
};

export default function ChangePassword() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useNavigate();
  const userDetail = useSelector((state: RootStateOrAny) => state.auth.user);
  const [schema, setSchema] = useState(yup.object());
  const policyDetail = useSelector((state: RootStateOrAny) => state.group_password_policy.group_password_policy);

  const handleSubmit = async (data: UnpackNestedValue<RegisterInput>) => {
    await start(passwordChange, data, dispatch);
  };
  useEffect(() => {
    start(groupPasswordPolicyDetail, { groupId: userDetail.groupId }, dispatch);
    const reg = getRegExpSchemaPasswordPolicy(policyDetail.isRequiredLowerAlphabetChars, policyDetail.isRequiredUpperAlphabetChars, policyDetail.isRequiredNumericChars, policyDetail.isRequiredMarkChars);
    let validateMsg = policyDetail.isRequiredLowerAlphabetChars ? t('PasswordLowerAlphabetChars') : '';
    validateMsg += policyDetail.isRequiredUpperAlphabetChars ? t('PasswordUpperAlphabetChars') : '';
    validateMsg += policyDetail.isRequiredNumericChars ? t('PasswordNumericChars') : '';
    validateMsg += policyDetail.isRequiredMarkChars ? t('PasswordMarkChars') : '';
    const schemaEdit = yup.object({
      oldPassword: yup
        .string()
        .required(t('muserCreate.msg.requiredPassword'))
        .max(127, t('muserCreate.msg.requiredPassword')),
      newPassword: yup
        .string()
        .required(t('muserCreate.msg.requiredPassword'))
        .min(policyDetail.minimumLength, t('Password107').replace('{0}', validateMsg).replace('{1}', policyDetail.minimumLength))
        .max(127, t('muserCreate.msg.requiredPassword'))
        .matches(reg, t('Password107').replace('{0}', validateMsg).replace('{1}', policyDetail.minimumLength)),
      newPasswordConfirm: yup
        .string()
        .oneOf([yup.ref('newPassword')], t('changePassword.msg.unmatchedNewPassword'))
        .required(t('changePassword.msg.requiredNewPasswordConfirm')),
    });
    setSchema(schemaEdit);
  }, []);

  return (
    <RegisterForm<RegisterInput, typeof schema>
      handleSubmit={handleSubmit}
      schema={schema}
      title={t('changePassword.msg.top')}
      isEditMode={false}
      handleCancel={() => history(routes.dashboard)}
    >
      {({ register, formState: { errors } }) => (
        <TGGrid container spacing={2} direction="row" justifyContent="start" alignItems="flex-start">
          <TGGrid item xs={12} sm={7}>
            <TGTextField
              label={t('changePassword.label.currentPassword')}
              type="password"
              registration={register('oldPassword')}
              isError={'oldPassword' in errors}
              errorMessage={errors.oldPassword?.message}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={7}>
            <Divider sx={{ mb: 2 }} />
            <TGTextField
              label={t('changePassword.label.newPassword')}
              type="password"
              registration={register('newPassword')}
              isError={'newPassword' in errors}
              errorMessage={errors.newPassword?.message}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={7}>
            <TGTextField
              label={t('changePassword.label.NewPasswordConfirm')}
              type="password"
              registration={register('newPasswordConfirm')}
              isError={'newPasswordConfirm' in errors}
              errorMessage={errors.newPasswordConfirm?.message}
            />
          </TGGrid>
        </TGGrid>
      )}
    </RegisterForm>
  );
}
