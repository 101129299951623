import * as yup from 'yup';
import { t } from 'i18next';
import { CsvFormat, TableColumn } from '../../../types';

export type Column = TableColumn & {
  id: 'no' | 'groupCode' | 'loginId' | 'password' | 'userName' | 'countryId' | 'email' | 'nickName' | 'isInvalid' | 'isUpdate';
};

export const columns: Column[] = [
  {
    id: 'no',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'loginId',
    label: 'common.label.userid',
  },
  {
    id: 'groupCode',
    label: 'common.label.groupid',
  },
  {
    id: 'password',
    label: 'common.label.password',
  },
  {
    id: 'userName',
    label: 'common.label.username',
  },
  {
    id: 'email',
    label: 'common.label.email',
  },
  {
    id: 'countryId',
    label: 'common.label.countryId',
  },
  {
    id: 'nickName',
    label: 'common.label.nickname',
  },
  {
    id: 'isInvalid',
    label: 'common.label.invalid',
    align: 'center',
  },
  {
    id: 'isUpdate',
    label: 'common.label.import.status',
    align: 'center',
  },
];
export type UserFormInput = {
  groupCode: string;
  loginId: string;
  userName: string;
  email: string;
  countryId: string;
  nickName: string;
  isUpdate: boolean;
};

export const rowsCsvFormat: CsvFormat[] = [
  {
    no: 'userImport.label.csvANo',
    name: 'userImport.label.csvAName',
    required: false,
    description: 'userImport.label.csvADescription',
  },
  {
    no: 'userImport.label.csvBNo',
    name: 'userImport.label.csvBName',
    required: true,
    description: 'userImport.label.csvBDescription',
  },
  {
    no: 'userImport.label.csvCNo',
    name: 'userImport.label.csvCName',
    required: false,
    description: 'userImport.label.csvCDescription',
  },
  {
    no: 'userImport.label.csvDNo',
    name: 'userImport.label.csvDName',
    required: true,
    description: '',
  },
  {
    no: 'userImport.label.csvENo',
    name: 'userImport.label.csvEName',
    required: true,
    description: '',
  },
  {
    no: 'userImport.label.csvFNo',
    name: 'userImport.label.csvFName',
    required: true,
    description: 'userImport.label.csvFDescription',
  },
  {
    no: 'userImport.label.csvGNo',
    name: 'userImport.label.csvGName',
    required: true,
    description: '',
  },
  {
    no: 'userImport.label.csvHNo',
    name: 'userImport.label.csvHName',
    required: true,
    description: 'userImport.label.csvHDescription',
  },
];
