/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable max-len */
import { Accordion, AccordionDetails, AccordionSummary, Alert, Button, Stack, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import axios from 'axios';
import { t } from 'i18next';
import { ReactNode, useState } from 'react';
import { FileUploader } from 'react-drag-drop-files';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import TGGrid from '../../../component/Elements/TGGrid';
import ImportForm from '../../../component/Layout/ImportForm';
import SearchResult from '../../../component/Layout/SearchResult';
import { BASE_URL, DEFAULT_PAGE, SIZE_PAGE } from '../../../constants/app';
import routes from '../../../constants/routes';
import { changeLoading } from '../../../state/actions/app';
import { Column, columns, rowsCsvFormat } from './types';
import CsvFormat from '../../../component/Layout/CsvFormat';
import { ColumnError, columnsError } from '../../../types';

export default function UserImport() {
  const fileTypes = ['csv'];
  const history = useNavigate();
  const dispatch = useDispatch();

  const [file, setFile] = useState<File>();
  const [rowsData, setRowsData] = useState<any[]>([]);
  const [rowsError, setRowsError] = useState<any[]>([]);
  const [page, setPage] = useState<number>(DEFAULT_PAGE);
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);
  const [errMsg, setErrMsg] = useState<string>('');
  const [successMsg, setSuccessMsg] = useState<string>('');
  const [descriptionMsg, setDescription] = useState<string>(t('userImport.label.topDescription'));

  const totalCount = () => (isError ? rowsError.length : rowsData.length);
  const resultData = () => (isError ? rowsError : rowsData).slice((page - 1) * SIZE_PAGE, page * SIZE_PAGE);
  const pageChange = (_: any, _page: number) => {
    setPage(_page);
  };

  const handleUpload = async (fileUpload: File) => {
    setIsUpload(false);
    setIsError(false);
    setRowsData([]);
    setRowsError([]);
    setFile(fileUpload);
    dispatch(changeLoading(true));
    const formData = new FormData();
    formData.append('file', fileUpload as Blob);
    await axios({
      url: `${BASE_URL}/user/import/check`,
      method: 'POST',
      data: formData,
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
        'content-type': 'multipart/form-data',
      },
    }).then((response) => {
      setRowsData(response.data.listResult);
      setRowsError(response.data.listError);
      setIsUpload(true);
      setIsError(response.data.isError);
      dispatch(changeLoading(false));
    });
  };

  const handleSaveCsvImport = async () => {
    try {
      dispatch(changeLoading(true));
      const formData = new FormData();
      formData.append('file', file as Blob);
      await axios({
        url: `${BASE_URL}/user/import/save`,
        method: 'POST',
        data: formData,
        headers: {
          Authorization: `Bearer ${localStorage.getItem('token')}`,
          'content-type': 'multipart/form-data',
        },
      }).then(() => {
        setErrMsg('');
        setSuccessMsg(t('common.msg.success'));
        setDescription('');
      });
      dispatch(changeLoading(false));
    } catch (error: any) {
      setSuccessMsg('');
      dispatch(changeLoading(false));
      if (error.status === 401) {
        setErrMsg(t(error.data.message));
      } else if (error.status === 400) {
        const err = error.data.errorCode ? error.data.errorCode : error.data.replace(/[^0-9]/g, '');
        setErrMsg(t(err));
      } else {
        setErrMsg(t('common.msg.erros'));
      }
    }
  };

  return (
    <ImportForm
      title={t('userImport.label.top')}
      description={descriptionMsg}
      handleCancel={() => history(routes.userSearch)}
    >
      {successMsg === '' && (
        <TGGrid my={2}>
          <Accordion
            sx={{
              border: '1px solid #7fb1a9',
              background: '#edf3f2',
              mb: 2,
              '&.Mui-expanded:last-of-type': {
                mb: 2,
              },
            }}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              sx={{
                height: 30,
                minHeight: 30,
                '&.Mui-expanded': {
                  height: 30,
                  minHeight: 30,
                },
              }}
            >
              <Typography>{t('userImport.label.csvFormatDescription')}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography>{t('userImport.label.csvFormatHeaderDescription')}</Typography>
              <CsvFormat rows={rowsCsvFormat} />
            </AccordionDetails>
          </Accordion>
          <FileUploader
            label={t('userImport.btn.fileUpload')}
            handleChange={handleUpload}
            name="file"
            types={fileTypes}
          />
          <Button
            disabled={!isUpload || isError}
            onClick={handleSaveCsvImport}
            variant="contained"
            sx={{ my: 2 }}
            color="primary"
          >
            {t('userImport.btn.import')}
          </Button>
        </TGGrid>
      )}
      <TGGrid>
        {errMsg !== '' && <Alert severity="error">{errMsg}</Alert>}
        {successMsg !== '' && <Alert severity="success">{successMsg}</Alert>}
      </TGGrid>
      {successMsg !== '' && (
        <TGGrid>
          <Button onClick={() => history(routes.userSearch)} variant="contained" sx={{ my: 1, mx: 1 }} color="primary">
            {t('userImport.btn.close')}
          </Button>
        </TGGrid>
      )}
      {isUpload && (
        <>
          <Stack mt={0} sx={{ padding: 1 }}>
            <Typography color={isError ? 'red' : 'blue'}>
              {isError
                ? t('userImport.msg.ng')
                : t('userImport.msg.ok')}
            </Typography>
          </Stack>
          <TGGrid>
            {!isError && (
              <SearchResult<Column>
                totalCount={totalCount()}
                page={page}
                columns={columns}
                rows={resultData()}
                handlePageChange={pageChange}
                // eslint-disable-next-line react/no-unstable-nested-components
                tableCell={(row: any, rowKey: number, column: Column): ReactNode => {
                  const value = row[column.id];
                  switch (column.id) {
                    case 'isUpdate':
                      return value ? (
                        <Typography component="div" variant="body1">
                          <Box sx={{ color: 'blue' }}>{t('userImport.label.update')}</Box>
                        </Typography>
                      ) : (
                        <Typography component="div" variant="body1">
                          <Box sx={{ color: 'red' }}>{t('userImport.label.new')}</Box>
                        </Typography>
                      );
                    case 'isInvalid':
                      return value ? <LockOutlinedIcon color="error" /> : '';
                    default:
                      return value;
                  }
                }}
              />
            )}
            {isError && (
              <SearchResult<ColumnError>
                totalCount={totalCount()}
                page={page}
                columns={columnsError}
                rows={resultData()}
                handlePageChange={pageChange}
                tableCell={(row: any, rowKey: number, column: ColumnError): ReactNode => {
                  const value = row[column.id];
                  switch (column.id) {
                    case 'errorMsg':
                      return t(value);
                    default:
                      return value;
                  }
                }}
              />
            )}
          </TGGrid>
        </>
      )}
    </ImportForm>
  );
}
