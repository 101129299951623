/* eslint-disable max-len */
/* eslint-disable react/require-default-props */

import { Container, Button, Alert } from '@mui/material';
import { t } from 'i18next';
import DriveFileRenameOutlineOutlinedIcon from '@mui/icons-material/Save';
import { useEffect, useState } from 'react';
import { SubmitHandler, UnpackNestedValue, useForm, UseFormProps, UseFormReturn } from 'react-hook-form';
import { AnyObject, ObjectShape, OptionalObjectSchema, TypeOfShape } from 'yup/lib/object';
import { yupResolver } from '@hookform/resolvers/yup';
import { RootStateOrAny, useDispatch, useSelector } from 'react-redux';
import MainCard from '../maincard';
import { changeEditable } from '../../state/actions/app';

type RegisterFormProps<TFormValues, Schema> = {
  handleSubmit: (data: UnpackNestedValue<TFormValues>, method: UseFormReturn<TFormValues, object>) => Promise<void>;
  handleCancel: () => void;
  children: (methods: UseFormReturn<TFormValues>) => React.ReactNode;
  options?: UseFormProps<TFormValues>;
  title: string;
  isEditMode: boolean;
  readOnly?: boolean;
  schema?: Schema;
};

export default function RegisterForm<
  TFormValues extends Record<string, unknown> = Record<string, unknown>,
  Schema extends OptionalObjectSchema<ObjectShape, AnyObject, TypeOfShape<ObjectShape>> = OptionalObjectSchema<
    ObjectShape,
    AnyObject,
    TypeOfShape<ObjectShape>
  >
>({
  handleSubmit,
  handleCancel,
  children,
  options,
  title,
  isEditMode,
  readOnly = false,
  schema,
}: RegisterFormProps<TFormValues, Schema>) {
  const methods: UseFormReturn<TFormValues, object> = useForm<TFormValues>({
    ...options,
    resolver: schema && yupResolver(schema),
  });

  const dispatch = useDispatch();

  const [errMsg, setErrMsg] = useState<string>('');
  const [successMsg, setSuccessMsg] = useState<string>('');
  const isEditable = useSelector((state: RootStateOrAny) => state.app.isEditable);

  useEffect(() => {
    dispatch(changeEditable(!readOnly));
  }, []);

  const onSubmit: SubmitHandler<TFormValues> = async (data) => {
    try {
      await handleSubmit(data, methods);
      setErrMsg('');
      setSuccessMsg(t('common.msg.success'));
      dispatch(changeEditable(false));
    } catch (error: any) {
      setSuccessMsg('');
      if (error.status === 401) {
        setErrMsg(t(error.data.message));
      } else if (error.status === 400) {
        const err = error.data.errorCode ? error.data.errorCode : error.data.replace(/[^0-9]/g, '');
        setErrMsg(t(err));
      } else {
        setErrMsg(t('common.msg.erros'));
      }
    }
  };

  return (
    <Container disableGutters maxWidth="xl" component="main">
      <MainCard
        title={title}
        secondary={(
          <>
            {isEditable && (
              <Button
                onClick={methods.handleSubmit(onSubmit)}
                variant="contained"
                sx={{ my: 1, mx: 1 }}
                color="primary"
                startIcon={<DriveFileRenameOutlineOutlinedIcon />}
              >
                {isEditMode ? t('register.msg.button.edit') : t('common.btn.regist')}
              </Button>
            )}
            <Button onClick={handleCancel} variant="contained" sx={{ my: 1, mx: 1 }} color="primary">
              {t('common.btn.cancel')}
            </Button>
          </>
        )}
      >
        {errMsg !== '' && (
          <Alert severity="error" sx={{ mb: 2 }}>
            {errMsg}
          </Alert>
        )}
        {successMsg !== '' && (
          <Alert severity="success" sx={{ mb: 2 }}>
            {successMsg}
          </Alert>
        )}
        {children(methods)}
      </MainCard>
    </Container>
  );
}
