/* eslint-disable max-len */
import * as yup from 'yup';
import { t } from 'i18next';
import { DEFAULT_PAGE } from '../../constants/app';
import { TableColumn } from '../../types';

export type RegisterInput = {
  groupCode: string;
  groupName: string;
  postcode: string;
  address: string;
  phoneNumber: string;
  isInvalid: boolean;
  countryId: string;
};

export const schemaEdit = yup.object({
  // groupCode: yup.string().required(('groupCreate.msg.requiredGroupid')).max(9, ('groupCreate.msg.requiredGroupid')),
  groupName: yup
    .string()
    .required(('groupCreate.msg.requiredGroupname'))
    .max(256, ('groupCreate.msg.requiredGroupname')),
  postcode: yup.string().required(('groupCreate.msg.requiredPostcode')).max(50, ('groupCreate.msg.requiredPostcode')),
  address: yup.string().required(('groupCreate.msg.requiredAddress')).max(512, ('groupCreate.msg.requiredAddress')),
  phoneNumber: yup.string().required(('groupCreate.msg.PhoneNumber')).max(20, ('groupCreate.msg.PhoneNumber')),
  countryId: yup.string().required(('groupCreate.msg.requiredCountryId')),
});

export const schemaNew = schemaEdit;

export type Column = TableColumn & {
  id:
    | 'groupId'
    | 'groupCode'
    | 'groupName'
    | 'countryId'
    | 'postcode'
    | 'address'
    | 'phoneNumber'
    | 'passwordPolicyLink'
    | 'isInvalid';
};

export const columns: Column[] = [
  {
    id: 'groupId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'groupCode',
    label: 'common.label.groupid',
  },
  {
    id: 'groupName',
    label: 'common.label.groupname',
  },
  {
    id: 'countryId',
    label: 'common.label.countryTitle',
  },
  {
    id: 'postcode',
    label: 'common.label.postcode',
  },
  {
    id: 'address',
    label: 'common.label.address',
  },
  {
    id: 'phoneNumber',
    label: 'common.label.phoneNumber',
  },
  {
    id: 'passwordPolicyLink',
    label: 'groupSearch.link.passwordPolicyLink',
  },
  {
    id: 'isInvalid',
    label: 'common.label.invalid',
    align: 'center',
  },
];

export type SearchCriteriaInput = {
  groupCode: string;
  groupName: string;
  countryId: string;
  isInvalid: boolean;
  pageIndex: number;
};

export const SearchCriteriaDefaultInput: SearchCriteriaInput = {
  groupCode: '',
  groupName: '',
  countryId: '',
  isInvalid: false,
  pageIndex: DEFAULT_PAGE,
};
