/* eslint-disable max-len */
import { Container, AppBar, Grid, Paper, styled, Typography, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSelector, RootStateOrAny } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import ApartmentIcon from '@mui/icons-material/Apartment';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import ComputerIcon from '@mui/icons-material/Computer';
import VerifiedIcon from '@mui/icons-material/Verified';
import hasRole from '../../lib/hasRole';
import routes from '../../constants/routes';
import MainCard from '../../component/maincard';

const CardWrapper = styled('main')(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  color: '#fff',
  overflow: 'hidden',
  position: 'relative',
  width: '100%',
  height: 120,
  borderRadius: 10,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&:after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    width: 100,
    height: 100,
    background: theme.palette.primary.light,
    borderRadius: '50%',
    top: -40,
    right: -40,
    [theme.breakpoints.down('sm')]: {
      top: -105,
      right: -140,
    },
  },
  cursor: 'pointer',
  textDecoration: 'none',
}));

function Dashboard() {
  const { t } = useTranslation();
  const history = useNavigate();
  const user = useSelector((state: RootStateOrAny) => state.auth.user);
  const hasAdmin = hasRole('systemAdmin', user);
  const hasGropuAdmin = hasRole('groupAdmin', user);
  const hasUser = hasRole('user', user);

  return (
    <Container disableGutters maxWidth="xl" component="main">
      <MainCard title={t('dashboard.label.top')}>
        <Grid sx={{ mb: 2, mt: 2 }}>
          {hasAdmin && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid onClick={() => history(routes.muserSearch)} item lg={3} md={6} sm={6} xs={12} sx={{ cursor: 'pointer' }}>
                  <Paper elevation={4} sx={{ height: 120, py: 1, px: 2 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                      <PersonSearchIcon style={{ fontSize: 40, color: '#203462' }} />
                      {t('dashboard.btn.userSearch')}
                    </Typography>
                    <Divider light sx={{ pt: 1, borderColor: '#b2bfdd' }} />
                    <Typography sx={{ fontSize: 12, pt: 1, color: '#5d6068' }}>
                      {t('dashboard.label.userSearchDescription')}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid onClick={() => history(routes.userSearch)} item lg={3} md={6} sm={6} xs={12} sx={{ cursor: 'pointer' }}>
                  <Paper elevation={4} sx={{ height: 120, py: 1, px: 2 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                      <AccountBoxIcon style={{ fontSize: 40, color: '#203462' }} />
                      {t('dashboard.btn.userMaster')}
                    </Typography>
                    <Divider light sx={{ pt: 1, borderColor: '#b2bfdd' }} />
                    <Typography sx={{ fontSize: 12, pt: 1, color: '#5d6068' }}>
                      {t('dashboard.label.userMasterDescription')}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid onClick={() => history(routes.groupSearch)} item lg={3} md={6} sm={6} xs={12} sx={{ cursor: 'pointer' }}>
                  <Paper elevation={4} sx={{ height: 120, py: 1, px: 2 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                      <ApartmentIcon style={{ fontSize: 40, color: '#203462' }} />
                      {t('dashboard.btn.groupSearch')}
                    </Typography>
                    <Divider light sx={{ pt: 1, borderColor: '#b2bfdd' }} />
                    <Typography sx={{ fontSize: 12, pt: 1, color: '#5d6068' }}>
                      {t('dashboard.label.groupSearchDescription')}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid onClick={() => history(routes.licenseUser)} item lg={3} md={6} sm={6} xs={12} sx={{ cursor: 'pointer' }}>
                  <Paper elevation={4} sx={{ height: 120, py: 1, px: 2 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                      <HowToRegIcon style={{ fontSize: 40, color: '#203462' }} />
                      {t('dashboard.btn.userLicense')}
                    </Typography>
                    <Divider light sx={{ pt: 1, borderColor: '#b2bfdd' }} />
                    <Typography sx={{ fontSize: 12, pt: 1, color: '#5d6068' }}>
                      {t('dashboard.label.userLicenseDescription')}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid onClick={() => history(routes.licenseDevice)} item lg={3} md={6} sm={6} xs={12} sx={{ cursor: 'pointer' }}>
                  <Paper elevation={4} sx={{ height: 120, py: 1, px: 2 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                      <ComputerIcon style={{ fontSize: 40, color: '#203462' }} />
                      {t('dashboard.btn.deviceSetting')}
                    </Typography>
                    <Divider light sx={{ pt: 1, borderColor: '#b2bfdd' }} />
                    <Typography sx={{ fontSize: 12, pt: 1, color: '#5d6068' }}>
                      {t('dashboard.label.deviceSettingDescription')}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid onClick={() => history(routes.licenseSearch)} item lg={3} md={6} sm={6} xs={12} sx={{ cursor: 'pointer' }}>
                  <Paper elevation={4} sx={{ height: 120, py: 1, px: 2 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                      <VerifiedIcon style={{ fontSize: 40, color: '#203462' }} />
                      {t('dashboard.btn.licenseMaster')}
                    </Typography>
                    <Divider light sx={{ pt: 1, borderColor: '#b2bfdd' }} />
                    <Typography sx={{ fontSize: 12, pt: 1, color: '#5d6068' }}>
                      {t('dashboard.label.licenseMasterDescription')}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          )}
          {hasGropuAdmin && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid onClick={() => history(routes.userSearch)} item lg={3} md={6} sm={6} xs={12} sx={{ cursor: 'pointer' }}>
                  <Paper elevation={4} sx={{ height: 120, py: 1, px: 2 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                      <AccountBoxIcon style={{ fontSize: 40, color: '#203462' }} />
                      {t('dashboard.btn.userMaster')}
                    </Typography>
                    <Divider light sx={{ pt: 1, borderColor: '#b2bfdd' }} />
                    <Typography sx={{ fontSize: 12, pt: 1, color: '#5d6068' }}>
                      {t('dashboard.label.userMasterDescription')}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid onClick={() => history(routes.groupSearch)} item lg={3} md={6} sm={6} xs={12} sx={{ cursor: 'pointer' }}>
                  <Paper elevation={4} sx={{ height: 120, py: 1, px: 2 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                      <ApartmentIcon style={{ fontSize: 40, color: '#203462' }} />
                      {t('dashboard.btn.groupSearch')}
                    </Typography>
                    <Divider light sx={{ pt: 1, borderColor: '#b2bfdd' }} />
                    <Typography sx={{ fontSize: 12, pt: 1, color: '#5d6068' }}>
                      {t('dashboard.label.groupSearchDescription')}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid onClick={() => history(routes.licenseUser)} item lg={3} md={6} sm={6} xs={12} sx={{ cursor: 'pointer' }}>
                  <Paper elevation={4} sx={{ height: 120, py: 1, px: 2 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                      <HowToRegIcon style={{ fontSize: 40, color: '#203462' }} />
                      {t('dashboard.btn.userLicense')}
                    </Typography>
                    <Divider light sx={{ pt: 1, borderColor: '#b2bfdd' }} />
                    <Typography sx={{ fontSize: 12, pt: 1, color: '#5d6068' }}>
                      {t('dashboard.label.userLicenseDescription')}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid onClick={() => history(routes.licenseDevice)} item lg={3} md={6} sm={6} xs={12} sx={{ cursor: 'pointer' }}>
                  <Paper elevation={4} sx={{ height: 120, py: 1, px: 2 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                      <ComputerIcon style={{ fontSize: 40, color: '#203462' }} />
                      {t('dashboard.btn.deviceSetting')}
                    </Typography>
                    <Divider light sx={{ pt: 1, borderColor: '#b2bfdd' }} />
                    <Typography sx={{ fontSize: 12, pt: 1, color: '#5d6068' }}>
                      {t('dashboard.label.deviceSettingDescription')}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          )}
          {hasUser && (
            <Grid item xs={12}>
              <Grid container spacing={3}>
                <Grid onClick={() => history(routes.userSearch)} item lg={3} md={6} sm={6} xs={12} sx={{ cursor: 'pointer' }}>
                  <Paper elevation={4} sx={{ height: 120, py: 1, px: 2 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                      <AccountBoxIcon style={{ fontSize: 40, color: '#203462' }} />
                      {t('dashboard.btn.userMaster')}
                    </Typography>
                    <Divider light sx={{ pt: 1, borderColor: '#b2bfdd' }} />
                    <Typography sx={{ fontSize: 12, pt: 1, color: '#5d6068' }}>
                      {t('dashboard.label.userMasterDescription')}
                    </Typography>
                  </Paper>
                </Grid>
                <Grid onClick={() => history(routes.licenseDevice)} item lg={3} md={6} sm={6} xs={12} sx={{ cursor: 'pointer' }}>
                  <Paper elevation={4} sx={{ height: 120, py: 1, px: 2 }}>
                    <Typography sx={{ fontSize: 16, fontWeight: 600, display: 'flex', alignItems: 'center' }}>
                      <ComputerIcon style={{ fontSize: 40, color: '#203462' }} />
                      {t('dashboard.btn.deviceSetting')}
                    </Typography>
                    <Divider light sx={{ pt: 1, borderColor: '#b2bfdd' }} />
                    <Typography sx={{ fontSize: 12, pt: 1, color: '#5d6068' }}>
                      {t('dashboard.label.deviceSettingDescription')}
                    </Typography>
                  </Paper>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
      </MainCard>
    </Container>
  );
}

export default Dashboard;
