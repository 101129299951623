import LanguageDetector from 'i18next-browser-languagedetector';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import translationEN from '../locales/en/translation.json';
import translationJA from '../locales/ja/translation.json';
import translationCN from '../locales/cn/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  ja: {
    translation: translationJA
  },
  cn: {
    translation: translationCN
  }
};

const langDetectorOptions = {
  // order and from where user language should be detected
  order: ['localStorage', 'cookie', 'navigator'],

  // keys or params to lookup language from
  lookupCookie: 'locale',
  lookupLocalStorage: 'locale',

  // cache user language on
  caches: ['localStorage', 'cookie'],
  excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

  // only detect languages that are in the whitelist
  checkWhitelist: true,
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    detection: langDetectorOptions,
    supportedLngs: ['ja', 'en', 'cn'],
    lng: 'ja',
    resources,
    fallbackLng: 'ja',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
