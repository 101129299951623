import * as yup from 'yup';
import { t } from 'i18next';
import moment from 'moment';
import { DEFAULT_PAGE } from '../../constants/app';
import { TableColumn } from '../../types';

export type RegisterInput = {
  groupId: string | null;
  licenseCode: string;
  availableDeviceNumber: number;
  startDate: string;
  endDate: string;
  isInvalid: boolean;
};

export const schemaEdit = yup.object({
  groupId: yup.string().typeError('muserCreate.msg.requiredGroup').required('muserCreate.msg.requiredGroup'),
  // licenseCode: yup
  //   .string()
  //   .required(t('licenseCreate.msg.requiredLicense'))
  //   .max(100, t('licenseCreate.msg.requiredLicense')),
  availableDeviceNumber: yup
    .number()
    .typeError('licenseCreate.msg.availableDeviceNumber')
    .required('licenseCreate.msg.availableDeviceNumber')
    .max(100, 'licenseCreate.msg.availableDeviceNumber')
    .min(0, 'licenseCreate.msg.availableDeviceNumber'),
  startDate: yup
    .date()
    .typeError('licenseCreate.msg.formatDate')
    .required('licenseCreate.msg.requiredStartDate')
    .min('2000/01/01', 'licenseCreate.msg.formatDate')
    .max('2999/12/31', 'licenseCreate.msg.formatDate'),
  endDate: yup
    .date()
    .typeError('licenseCreate.msg.formatDate')
    .required('licenseCreate.msg.requiredEndDate')
    .min('2000/01/01', 'licenseCreate.msg.formatDate')
    .max('2999/12/31', 'licenseCreate.msg.formatDate'),
});

export const schemaNew = schemaEdit;

export type Column = TableColumn & {
  id:
    | 'licenseId'
    | 'groupNameWithCode'
    | 'licenseCode'
    | 'availableDeviceNumber'
    | 'startDate'
    | 'endDate'
    | 'isInvalid';
};

export const columns: Column[] = [
  {
    id: 'licenseId',
    label: 'No.',
    align: 'center',
  },
  {
    id: 'groupNameWithCode',
    label: 'common.label.groupnameGroupid',
  },
  {
    id: 'licenseCode',
    label: 'common.label.licenseCode',
  },
  {
    id: 'availableDeviceNumber',
    label: 'common.label.availableDeviceNumber',
    align: 'center',
  },
  {
    id: 'startDate',
    label: 'common.label.startDate',
    format: (value: Date) => moment(value).format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'endDate',
    label: 'common.label.endDate',
    format: (value: Date) => moment(value).format('YYYY/MM/DD'),
    align: 'center',
  },
  {
    id: 'isInvalid',
    label: 'common.label.invalid',
    align: 'center',
  },
];

export type SearchCriteriaInput = {
  groupId: string | null;
  isIncludeExpired: boolean;
  isInvalid: boolean;
  pageIndex: number;
};

export const SearchCriteriaDefaultInput: SearchCriteriaInput = {
  groupId: '',
  isIncludeExpired: true,
  isInvalid: false,
  pageIndex: DEFAULT_PAGE,
};
