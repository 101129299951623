/* eslint-disable no-alert */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable max-len */
import { ReactNode, useEffect, useState } from 'react';
import { useSelector, RootStateOrAny, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { UnpackNestedValue, useForm } from 'react-hook-form';
import DoneOutlineIcon from '@mui/icons-material/DoneOutline';
import { Button, IconButton } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { useLocation, useNavigate } from 'react-router-dom';
import { getGroups } from '../../../state/actions/group';
import { start } from '../../../lib/saga-promise';
import roles from '../../../constants/roles';
import { exportCsv } from '../../../component/helpers/utility';
import SearchCriteria from '../../../component/Layout/SearchCriteria';
import SearchForm from '../../../component/Layout/SearchForm';
import SearchResult from '../../../component/Layout/SearchResult';
import GroupSelector from '../../../component/Form/GroupSelector';
import DownloadButton from '../../../component/Form/DownloadButton';
import { Column, columns, SearchCriteriaDefaultInput, SearchCriteriaInput } from './types';
import TGGrid from '../../../component/Elements/TGGrid';
import TGTextField from '../../../component/Elements/TGTextField';
import { cancelDevice, resetCancelDevice, searchDevice } from '../../../state/actions/device';
import { DEFAULT_PAGE, SIZE_PAGE } from '../../../constants/app';
import OverHideText from '../../../component/Elements/OverHideText';

const localStorageKey = 'searchDeviceLicense';

export default function LicenseDevice() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const query = new URLSearchParams(useLocation().search);

  const { register, handleSubmit, setValue, reset, getValues, watch } = useForm<SearchCriteriaInput>({
    defaultValues: SearchCriteriaDefaultInput,
  });

  const rows = useSelector((state: RootStateOrAny) => state.device);
  const groups = useSelector((state: RootStateOrAny) => state.group.allGroups);
  const user = useSelector((state: RootStateOrAny) => state.auth.user);

  useEffect(() => {
    start(getGroups, { noLoading: true }, dispatch);
    if (query.get('licenseCode')) {
      setValue('licenseCode', String(query.get('licenseCode')));
    } else {
      setValueFromLocalStorage();
    }

    handleSubmit(searchSubmit)();
  }, []);

  const handleSearch = () => {
    setValue('pageIndex', DEFAULT_PAGE);
    handleSubmit(searchSubmit)();
  };

  const searchSubmit = async (data: UnpackNestedValue<SearchCriteriaInput>) => {
    setCancelEditMode(false);
    setResetCancelEditMode(false);
    await start(searchDevice, data, dispatch);
    setItemLocalStorage({ ...data });
  };

  const setValueFromLocalStorage = () => {
    const searchLocalStorage = localStorage.getItem(localStorageKey);
    if (searchLocalStorage) {
      reset(JSON.parse(searchLocalStorage) as SearchCriteriaInput);
    }
  };

  const setItemLocalStorage = (params: SearchCriteriaInput) => {
    localStorage.setItem(localStorageKey, JSON.stringify(params));
  };

  const pageChange = (_: any, page: number) => {
    setValue('pageIndex', page);
    handleSubmit(searchSubmit)();
  };

  const handleExportCsv = (data: UnpackNestedValue<SearchCriteriaInput>) => {
    const url = `/device/export?groupId=${encodeURIComponent(data.groupId ?? '')}&licenseCode=${encodeURIComponent(data.licenseCode)}&username=${encodeURIComponent(data.username)}`;
    exportCsv(url, 'DeviceLicense');
  };

  const [isCancelEditMode, setCancelEditMode] = useState<boolean>(false);
  const [isResetCancelEditMode, setResetCancelEditMode] = useState<boolean>(false);
  const [editLicenseDeviceBindId, setEditLicenseDeviceBindId] = useState<string>('');

  const editCancelBindDevice = async (licenseDeviceBindId: string) => {
    setCancelEditMode(true);
    setResetCancelEditMode(false);
    setEditLicenseDeviceBindId(licenseDeviceBindId);
  };
  const cancelCancelEditMode = () => {
    setCancelEditMode(false);
    setEditLicenseDeviceBindId('');
  };
  const doneCancelEditMode = async () => {
    await start(cancelDevice, { licenseDeviceBindId: editLicenseDeviceBindId, noLoading: true }, dispatch);
    setCancelEditMode(false);
    setEditLicenseDeviceBindId('');
    handleSubmit(searchSubmit)();
  };

  const editResetCancelBindDevice = async (licenseDeviceBindId: string) => {
    setResetCancelEditMode(true);
    setCancelEditMode(false);
    setEditLicenseDeviceBindId(licenseDeviceBindId);
  };

  const cancelResetCancelEditMode = () => {
    setResetCancelEditMode(false);
    setEditLicenseDeviceBindId('');
  };
  const doneResetCancelEditMode = async () => {
    try {
      await start(resetCancelDevice, { licenseDeviceBindId: editLicenseDeviceBindId, noLoading: true }, dispatch);
      setResetCancelEditMode(false);
      setEditLicenseDeviceBindId('');
      handleSubmit(searchSubmit)();
    } catch (error: any) {
      if (error.status === 401) {
        alert(t(error.data.message));
      } else if (error.status === 400) {
        const err = error.data.errorCode ? error.data.errorCode : error.data.replace(/[^0-9]/g, '');
        alert(t(err));
      } else {
        alert(t('common.msg.erros'));
      }
    }
  };

  return (
    <SearchForm title={t('licenseDevice.label.top')} description={t('licenseDevice.label.description')}>
      <TGGrid sx={{ mb: 2, mt: 2 }}>
        <SearchCriteria
          handleRefresh={() => reset(SearchCriteriaDefaultInput)}
          handleSearch={handleSearch}
        >
          <TGGrid item xs={12} sm={12} md={4} userRoleId={user.roleId} showRoles={[roles.systemAdmin]}>
            <GroupSelector
              groupId={watch('groupId')}
              handleChange={(data) => setValue('groupId', data?.groupId ?? null)}
              groups={groups}
            />
          </TGGrid>
          <TGGrid item xs={12} sm={4}>
            <TGTextField registration={register('licenseCode')} label={t('common.label.licenseCode')} />
          </TGGrid>
          <TGGrid item xs={12} sm={4}>
            <TGTextField registration={register('username')} label={t('common.label.username')} />
          </TGGrid>
        </SearchCriteria>
      </TGGrid>
      <TGGrid item xs={12} sx={{ float: 'right', mb: 2 }}>
        <DownloadButton handleClick={handleSubmit(handleExportCsv)} />
      </TGGrid>

      <TGGrid>
        <SearchResult<Column>
          totalCount={rows.total}
          page={getValues('pageIndex')}
          columns={columns}
          rows={rows?.devices}
          handlePageChange={pageChange}
          tableCell={(row: any, rowKey: number, column: Column): ReactNode => {
            const value = row[column.id];
            switch (column.id) {
              case 'licenseDeviceId':
                return rowKey + 1 + (getValues('pageIndex') - 1) * SIZE_PAGE;
              case 'groupNameWithCode':
                return `${row.groupName} < ${row.groupCode} > `;
              case 'UserNameWithLoginId':
                return `${row.userName} < ${row.loginId} > `;

              case 'cancelAction':
                if (isCancelEditMode && editLicenseDeviceBindId === row.licenseDeviceBindId) {
                  return (
                    <>
                      <IconButton color="success" onClick={doneCancelEditMode}>
                        <DoneOutlineIcon />
                      </IconButton>
                      <IconButton color="error" onClick={cancelCancelEditMode}>
                        <CancelIcon />
                      </IconButton>
                    </>
                  );
                }
                return (
                  <Button
                    onClick={() => editCancelBindDevice(row.licenseDeviceBindId)}
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={
                      row.deviceCancelDate != null
                      || (isCancelEditMode && editLicenseDeviceBindId !== row.licenseDeviceBindId)
                    }
                  >
                    {t('common.btn.release')}
                  </Button>
                );

              case 'resetCancelAction':
                if (isResetCancelEditMode && editLicenseDeviceBindId === row.licenseDeviceBindId) {
                  return (
                    <>
                      <IconButton color="success" onClick={doneResetCancelEditMode}>
                        <DoneOutlineIcon />
                      </IconButton>
                      <IconButton color="error" onClick={cancelResetCancelEditMode}>
                        <CancelIcon />
                      </IconButton>
                    </>
                  );
                }
                return (
                  <Button
                    onClick={() => editResetCancelBindDevice(row.licenseDeviceBindId)}
                    variant="contained"
                    color="error"
                    size="small"
                    disabled={
                      row.deviceCancelDate == null
                      || (isResetCancelEditMode && editLicenseDeviceBindId !== row.licenseDeviceBindId)
                    }
                  >
                    {t('取消')}
                  </Button>
                );

              default:
                return column.format ? column.format(value) : <OverHideText>{value}</OverHideText>;
            }
          }}
        />
      </TGGrid>
    </SearchForm>
  );
}
