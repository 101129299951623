import { call, put, takeLatest } from 'redux-saga/effects';
import LicenseServices from '../../services/license';
import { types, searchLicensesSuccess, licenseDetailSuccess, editLicenseSuccess, createLicenseSuccess, licenseImportUserSuccess, licenseNewKeySuccess } from '../actions/license';
import { sagaPromise } from '../../lib/saga-promise';
import { changeLoading } from '../actions/app';

export interface ResponseGenerator {
  config?: any,
  data?: any,
  headers?: any,
  request?: any,
  status?: number,
  statusText?: string
}

interface Iaction {
  type: string,
  payload: any,
}

function* createLicense(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.createLicense, action.payload);
    yield put(createLicenseSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* editLicense(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.editLicense, action.payload);
    yield put(editLicenseSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* searchLicenses(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.searchLicenses, action.payload);
    yield put(searchLicensesSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* licenseDetail(action: Iaction) {
  try {
    if (!action.payload.noLoading) {
      // yield put(changeLoading(true));
    }
    const data: ResponseGenerator = yield call(LicenseServices.licenseDetail, action.payload);
    yield put(licenseDetailSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* licenseImportUser(action: Iaction) {
  try {
    // yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.licenseImportUser, action.payload);
    yield put(licenseImportUserSuccess(data));
    yield put(changeLoading(false));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}

function* getNewKeyLicense(action: Iaction) {
  try {
    yield put(changeLoading(true));
    const data: ResponseGenerator = yield call(LicenseServices.licenseNewKey, action.payload);
    yield put(licenseNewKeySuccess(data));
    return data;
  } catch (error) {
    yield put(changeLoading(false));
    throw error;
  }
}



export default function* licenseSaga() {
  yield takeLatest(types.CREATE_LICENSE, sagaPromise(createLicense));
  yield takeLatest(types.EDIT_LICENSE, sagaPromise(editLicense));
  yield takeLatest(types.SEARCH_LICENSES, sagaPromise(searchLicenses));
  yield takeLatest(types.LICENSE_DETAIL, sagaPromise(licenseDetail));
  yield takeLatest(types.LICENSE_IMPORT_USER, sagaPromise(licenseImportUser));
  yield takeLatest(types.LICENSE_NEW_KEY, sagaPromise(getNewKeyLicense));
}
